import { Observable, Subject, Subscription } from 'rxjs';
// import { isNullOrUndefined, isDate } from 'util';

import { MessageCodes } from '../app.config';

export class AppSharedService {

  public IsAuthUser: boolean = false;
  public IsAuthUser$ = new Subject<any>();

  // public UserInfo: UserInfo;
  public UserInfo: any;
  public UserInfo$ = new Subject<any>();

  public ApiToken: any;
  public ApiToken$ = new Subject<any>();

  public UserAccessInfo: any;

  public DowntimeMessage:any;
  public DowntimeMessage$ = new Subject<any>();

  public OrgID!: string;
  public AppID!: string;

  public CurrentComponent: any;
  // public CurrentComponent$: Observable<any>;
  // public CurrentComponentObserver: any;

  public AppIsLoading$ = new Subject<boolean>();
  public AppLoaderSubscription!: Subscription;

  public ActingUser$ = new Subject<string>();
  public SelectedLanguage$ = new Subject<string>();

  public selectedLang!: string;
  public urlLang!: string;

  public containerData: any = {};
  public containerData$ = new Subject<string>();

  public currentRoleData: any;
  public currentRoleData$ = new Subject<string>();

  // public AppReadonly: boolean = environment.AppReadOnly;
  public LanguageDetail: any[] = [];
  public UserRoleList: any[] = [];
  // By default, date format for lang 'en-US' is set.
  public AppDateFormat: string = 'dd-MMM-yyyy';
  // public AppDateTimeFormat: string = 'dd-MMM-yyyy hh:mm:ss a';
  public AppDateTimeFormat: string = 'dd-MMM-yyyy hh:mm';
  // public canResetTab

  public employeeRoleCode: number = 0;

  constructor() {

    // this.UserInfo$ = new Observable(observer => this.UserInfoObserver = observer);
    // this.ApiToken$ = new Observable(observer => this.ApiTokenObserver = observer);

  }

  public setUserInfo(value: any) {
    this.UserInfo = value;
    this.UserInfo$.next(this.UserInfo);

    console.log('UserInfo.email >> '+ this.UserInfo.email);

    // // Get the Org name from the user mail id
    // let usrmail: string = this.UserInfo.email;
    // usrmail = usrmail.substr(usrmail.indexOf('@') + 1, usrmail.length);
    // // this.OrgID = usrmail.substring(0, usrmail.indexOf('.'));
    // // this.OrgID = this.OrgID.toUpperCase();
    // // // console.log('this.OrgID = '+ this.OrgID);

  }

  public setUserParam(actinguser: string, lang: string) {
    this.ActingUser$.next(actinguser);
    // this.SelectedLanguage$.next(lang);
    this.urlLang = lang;

  }

  public getUserInfo(): Observable<any> {
    return this.UserInfo;
  }

  public setApiToken(token: any, accessinfo: any) {
    this.ApiToken = token;
    this.UserAccessInfo = accessinfo;
     
    this.ApiToken$.next(this.ApiToken);
  }

  public getApiToken(): Observable<any> {
    return this.ApiToken;
  }

  // public setAppId(value: string) {
  //   this.AppID = value;
  // }

  public setCurrentComponent(componentdet: any) {
    this.CurrentComponent = componentdet;
    // this.CurrentComponentObserver.next(component);

    if (!isNullOrUndefined(this.CurrentComponent.ComponentReference)) {
      if (this.AppLoaderSubscription) {
        this.AppIsLoading$.next(false);
        this.AppLoaderSubscription.unsubscribe();
      }

      this.AppLoaderSubscription = this.CurrentComponent.ComponentReference.IsProcessing$.subscribe((flag: boolean) => {
        this.AppIsLoading$.next(flag);
      });
    }

  }

  // public getCurrentComponent(): Observable<any> {
  //   return this.CurrentComponent$;
  // }

  public getCurrentComponent(): any {
    return this.CurrentComponent;
  }

  private escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  public getMessage(msgcode: string, ...args: any[]): string {
    let msg = '', arg = '';

    // msg = MessageCodes[msgcode];
    msg = getProperty(MessageCodes, msgcode as any);

    // Check if place holders persent
    arg = '{args[';
    if (msg.indexOf(arg)) {
      msg = msg.replace(new RegExp(this.escapeRegExp(arg), 'g'), '$' + arg);
      msg = eval('`' + msg + '`');
    }

    return msg;
  }

  getDateOnly(inputdate: any) {
    let outputdt = null;
    if (!isNullOrUndefined(inputdate)) {
      let tmpdt = new Date(inputdate);
      if (isDate(tmpdt)) {
        outputdt = new Date(tmpdt.getFullYear(), tmpdt.getMonth(), tmpdt.getDate());
      }
    }
    return outputdt;
  }

  getDateOnlyStringWithoutTZ(inputdate: any): string {
    let outputdt: string = '';
    if (!isNullOrUndefined(inputdate)) {
      let tmpdt = new Date(inputdate);
      if (isDate(tmpdt)) {
        let date1 = new Date(tmpdt.getFullYear(), tmpdt.getMonth(), tmpdt.getDate());
        outputdt = new Date(date1.getTime() - date1.getTimezoneOffset() * 60000).toISOString();
      }
    }
    return outputdt;
  }

}

export function AutoUnsubscribe(constructor: any) {

  // reference to ngDestroy func defined
  const original = constructor.prototype.ngOnDestroy;

  constructor.prototype.ngOnDestroy = function () {
    // for (let prop in this) {
    //   if (this[prop] instanceof Subscription) {
    //     const property = this[prop];
    //     if (property && (typeof property.unsubscribe === 'function')) {
    //       property.unsubscribe();
    //     }
    //   }
    // }

    // original && typeof original === 'function' && original.apply(this, arguments);

    for (let prop in this) {
      // unsubscription prop
      if (this[prop] instanceof Subscription) {
        const property = this[prop];
        property.unsubscribe();
      }

      // unsubscription array
      if (Array.isArray(this[prop]) && this[prop][0] instanceof Subscription) {
        const property = this[prop];
        property.forEach((element: any) => {
          element.unsubscribe();
        });
      }

    }

    if (original && typeof original === 'function') { original.apply(this, arguments); }

  };

}

import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[focused]'
})

export class FocusedDirective {

  @Input()
  set focused(value: boolean) {
    if (value) {
      // this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'scrollIntoViewIfNeeded');
      // this.elementRef.nativeElement.scrollIntoViewIfNeeded();
      this.elementRef.nativeElement.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center' });
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
}


// export function isNullOrUndefined<T>(object: T | undefined | null): object is T {  
//   return <T>object !== undefined && <T>object !== null;  
// }  
export function isNullOrUndefined<T>(obj?: T | null): boolean {
  // null == undefined so this is true if obj = null or obj = undefined
  return obj == null;
}

export function isStringNullOrEmpty(obj: any): boolean {
  // null == undefined so this is true if obj = null or obj = undefined
  
  return (obj == null || obj.trim() == '') ? true : false;
}

export function isDate(obj: any): boolean {
  return obj instanceof Date && !isNaN(obj.getTime()) ? true : false;
};

export function isNumber(obj: any): boolean {
  return typeof obj === 'number';
};

export function isEmail(obj: any): boolean {
  return obj.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null ? false : true;
};

export function getProperty<Type, Key extends keyof Type>(obj: Type, key: Key) {
  return obj[key];
}

export function setProperty<Type, Key extends keyof Type>(obj: Type, key: Key, value: any) {
  return obj[key] = value;
}
export function isMobileNumber(obj: any): boolean {
  return obj.match(/^((\\+91-?)|0)?[0-9]{10}$/) == null ? false : true;
};