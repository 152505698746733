<div class="p-2 contentScroll simpleCard">
    <!--Start From Here -->
    <!-- <ng-container *ngIf="(checkDocumentDetailsList != null && checkDocumentDetailsList != undefined && 
                        checkDocumentDetailsList.length > 0)"> -->

    <ng-container>
        <div class="card h-100 m-0">

            <div class="card-body overflow-auto">
                <!-- <div class="gridContainer"> -->
                <ng-container *ngIf="(RequestProperty.ViewEmail != null && RequestProperty.ViewEmail != undefined &&
                         (RequestProperty.ViewEmail | filterBy: {HasPermission :true }).length > 0)">
                    <tabset [tabList]="(RequestProperty.ViewEmail | filterBy: {HasPermission :true })"
                        (selected)="setSelectedTab($event)">
                        <br><br>                            
                        <ng-container
                            *ngFor="let emailTabs of (RequestProperty.ViewEmail | filterBy: {HasPermission :true })">
                            <ng-container *ngIf="selectedTab==emailTabs.Name">
                                <div class="col-auto position-relative">
                                    <ng-container
                                        *ngIf="RequestTemplate!=null && RequestTemplate!=undefined && RequestTemplate.length > 0">
                                        <div class="p-3" *ngIf="selectedTab!='CreateEmail'">
                                            <grid-dispctrl #emailgrid
                                                [FieldTemplate]="RequestTemplate | filterBy: {Group: (emailTabs.Name+'_'+'grid')}"
                                                [GridProp]="GridProp" [GridData]="viewEmailData"
                                                [GridDataTotalCount]="viewEmailData.length"
                                                (FieldClick)="onClick($event)">
                                            </grid-dispctrl>
                                        </div>
                                        <div *ngIf="viewEmailTemp || selectedTab=='CreateEmail'"
                                            class="card-body overflow-auto">
                                            <customform #emailcustform
                                                [FormTemplate]="RequestTemplate | filterBy: {Group: (emailTabs.Name+'_'+'form')}"
                                                [FormData]="selectedInfoData" [FormProp]="FormProp"
                                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                            </customform>

                                            <br>

                                            <ng-container
                                                *ngIf="RequestProperty.Emailbuttons != null && RequestProperty.Emailbuttons != undefined && RequestProperty.Emailbuttons.length > 0">

                                                <div *ngIf="selectedTab=='CreateEmail'"
                                                    class="d-flex justify-content-start">

                                                    <ng-container
                                                        *ngFor="let actionBar of Emailbuttons  | filterBy: {IsApplicable: true,HasPermission :true}
                                                     | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

                                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                                            (click)="btnClick(actionBar)"
                                                            [disabled]="!actionBar.IsEnabled"
                                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                                            |
                                                            translate
                                                            }}
                                                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                                    class="ion ion-md-arrow-forward"
                                                                    aria-hidden="true"></i></span>
                                                        </button>

                                                    </ng-container>

                                                </div>
                                            </ng-container>
                                            <br>
                                            <!--Attachment Display section-->
                                            <ng-container
                                                *ngIf="selectedTab=='CreateEmail' && !DisplayAttachment && fileData.length>0 && fileData!=null ">

                                                <!-- <div style="text-align:left;" *ngIf="fileData.length > 0 && !DisplayAttachment">
                                                    <i id="Attachment" class="fa fa-file-archive-o" aria-hidden="true"></i>{{'
                                                    Attachments' | translate}}
            
                                                    <ng-container *ngFor="let files of fileData">
            
                                                        <li class="text-bold mb-1">
                                                            <ol>{{files.FileName}}</ol>
                                                        </li>
                                                    </ng-container>
                                                </div> -->

                                                <div class="table-responsive">
                                                    <table class="table table-sm table-bordered tblGrid fixed">
                                                        <caption class="sr-only">File Attachments Grid</caption>
                                                        <thead class="tblGridHeader">
                                                            <th width="40" scope="col">#</th>
                                                            <th class="text-truncate my-0" scope="col">File Name</th>
                                                            <th class="text-truncate my-0" scope="col">Created Date</th>
                                                            <th class="text-truncate my-0" scope="col">Created By</th>
                                                            <th width="75" scope="col">&nbsp;</th>
                                                        </thead>
                                                        <tbody
                                                            *ngIf="fileData!=null &&  fileData!=undefined && fileData.length>0">
                                                            <tr *ngFor="let item of fileData; let item_indx=index">
                                                                <td>
                                                                    <p class="text-truncate my-0">{{item_indx+1}}</p>
                                                                </td>
                                                                <td>
                                                                    <p class="text-truncate my-0"><a
                                                                            [attr.aria-label]="item?.FileName"
                                                                            href="javascript:;" class="cellLink"
                                                                            title="{{ item.FileName}}">{{
                                                                            item.FileName}}</a></p>
                                                                </td>
                                                                <!-- <td>
                                                                    <p class="text-truncate my-0" title="{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}">{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}</p>
                                                                    </td> -->
                                                                <td>
                                                                    <p class="text-truncate my-0"
                                                                        title="{{ item.CreatedName}}">
                                                                        {{ item.CreatedDate | date: 'dd/MM/yyyy'}}</p>
                                                                </td>

                                                                <td>
                                                                    <p class="text-truncate my-0"
                                                                        title="{{ item.CreatedBy}}">
                                                                        {{ item.CreatedBy}}</p>
                                                                </td>
                                                                <td>
                                                                    <ul class="actionList" style="text-align: center">
                                                                        <li>
                                                                            <!-- [tooltip]="('Click here to delete'| translate)" container="body" placement="top" -->
                                                                            <a aria-label="Delete" href="javascript:;"
                                                                                *ngIf="AttachPerm.Upload">

                                                                                <i class="fa fa-trash"
                                                                                    (click)="showDeleteConfirmation(item,item_indx)"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                    <!-- <p><button class="btn btn-primary" *ngIf="Permissions.Upload&&item.IsDeletable" (click)="showDeleteConfirmation(item)"><i class="fa fa-trash"></i></button></p> -->
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                        <tbody
                                                            *ngIf="fileData==null ||  fileData==undefined || fileData.length==0">
                                                            <tr>
                                                                <td colspan="5">No files attached.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <hr>



                                            </ng-container>




                                        </div>

                                    </ng-container>
                                </div>

                                <!-- <ng-container
                            *ngIf="EmailTemplate != null && EmailTemplate != undefined && EmailTemplate.length > 0">
                           
            
            
                        </ng-container> -->
                            </ng-container>
                        </ng-container>
                    </tabset>
                    <br>

                    <br>
                    <ng-container *ngIf="selectedTab!='CreateEmail'">
                        <div *ngIf="viewEmail || viewEmailTemp" class="card-body overflow-auto">
                            <div class="custom-email mt-4">
                                <div class="d-flex">
                                    <h5 class="mb-0">
                                        {{selectedEmailActionData.EmailSubject}}
                                    </h5>
                                    <p class="ms-auto mb-0">{{selectedEmailActionData.CreatedDate | date: 'dd-MMM-yyyy
                                        hh:mm'}}
                                    </p>
                                </div>
                                <br>
                                <p class="text-start mb-0"><b>From:</b>&nbsp; {{selectedEmailActionData.FromEmail}}</p>
                                <br>
                                <p class="text-start"><b>To:</b> {{selectedEmailActionData.ToEmail}}</p>
                                <br>
                                <p class="text-start"><b>CC:</b> {{selectedEmailActionData.CCEMail}}</p>
                                <hr>

                                <!--Attachment Download section-->

                                <ng-container *ngIf="selectedTab!='CreateEmail' && !DisplayAttachment && fileNames.length>0 && fileNames!=null">
                                    <div class="table-responsive">
                                        <table class="table table-sm table-bordered tblGrid fixed">
                                            <caption class="sr-only">File Download Grid</caption>
                                            <thead class="tblGridHeader">
                                                <th width="40" scope="col">#</th>
                                                <th class="text-truncate my-0" scope="col">File Name</th>
                                                <th class="text-truncate my-0" scope="col">Uploaded Date</th>
                                                <th width="75" scope="col">&nbsp;</th>
                                            </thead>
                                            <tbody *ngIf="fileNames!=null &&  fileNames!=undefined">
                                                <tr *ngFor="let item of fileNames; let item_indx=index">
                                                    <td>
                                                        <p class="text-truncate my-0">{{item_indx+1}}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-truncate my-0"><a
                                                                [attr.aria-label]="item?.FileName" href="javascript:;"
                                                                (click)="downloadFile(item)" class="cellLink"
                                                                title="{{item.FileName}}">{{item.FileName}}</a>
                                                        </p>
                                                    </td>
                                                    <!-- <td>
                                                    <p class="text-truncate my-0" title="{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}">{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}</p>
                                                    </td> -->


                                                    <td>
                                                        <p class="text-truncate my-0"
                                                            title="{{item.AttachmentCreatedDate}}">
                                                            {{ item.CreatedDate | date: 'dd/MM/yyyy'}}</p>
                                                    </td>



                                                </tr>
                                            </tbody>

                                            <tbody
                                                *ngIf="selectedEmailActionData==null ||  selectedEmailActionData==undefined || selectedEmailActionData.length==0">
                                                <tr>
                                                    <td colspan="5">No files.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </ng-container>
                                <hr>
                                <!-- <iframe srcdoc="{{selectedEmailActionData.EMailTemplate}}" sandbox="allow-same-origin"
                                    title="EMailTemplate" width="100%" height="500">
                                </iframe> -->

                                <div [innerHTML]="selectedEmailActionData.EMailTemplate"></div>
                            </div>
                        </div>
                    </ng-container>


                </ng-container>
                <!-- </div> -->
            </div>
        </div>

    </ng-container>

</div>

<ng-container *ngIf="viewEmailTemp || selectedTab=='CreateEmail'">
    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between">
            <div></div>
            <div>
                <ng-container
                    *ngIf="RequestProperty.Emailbuttons != null && RequestProperty.Emailbuttons != undefined && RequestProperty.Emailbuttons.length > 0">
                    <ng-container *ngFor="let actionBar of RequestProperty.Emailbuttons  | filterBy: {IsApplicable: true,HasPermission :true}
                      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">


                        <button type="button" [ngClass]="actionBar.CustomClass" (click)="btnClick(actionBar)"
                            [disabled]="!actionBar.IsEnabled"
                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                            translate
                            }}
                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                    aria-hidden="true"></i></span>
                        </button>


                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>








<!--Notification section-->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<!--Attachment section-->
<ng-container *ngIf="selectedTab=='CreateEmail' && DisplayAttachment">
    <file-upload [Module]="'Mail'" [RefId1]="RequestId" [RefId2]="" [RefId3]="" [RefId4]="" [IsMultiple]="true"
        (close)="closeAttachment($event)" [Permissions]="AttachPerm" [UploadType]="" [CandidateId]="">
    </file-upload>
</ng-container>