import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { BGVStatus, ComponentCodes, ComponentConfig, ProcessStatus, RoleCodes } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { FormComponent } from 'src/modules/form/form.component';
import { GridComponent } from 'src/modules/grid/grid.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { CheckInfo } from '../check/check.interface';
import { RequestService } from '../request.service';
import { ReportInfo } from './updateoverallstatus.interface';


@Component({
    selector: 'updateoverallstatus',
    templateUrl: './updateoverallstatus.component.html'
})

@AutoUnsubscribe
export class UpdateOverallStatusComponent implements OnInit {

    @Input() RequestId: number = 0;
    @Input() SelectedTabName: string = '';
    @Input() RequestData: any;

    @Output() public updateOverallStatusOnChange = new EventEmitter<any>();
    @Output() RefreshRequest = new EventEmitter<any>();

    @ViewChild('updateStatusgrid', { static: false }) updateStatusgrid!: GridComponent;
    // @ViewChild('reportgrid', { static: false }) reportgrid!: GridComponent;
    @ViewChild('reportForm', { static: false }) reportForm!: FormComponent;

    public updateOverallStatusTemplate: FieldTemplate[] = [];
    public updateOverallStatusPermission: any = [];
    public updateOverallStatusProperties: any = [];
    public updateOverallStatusData: CheckInfo[] = [];
    public updateOverallAdditionalSectionData: any[] = [];

    public reportTemplate: FieldTemplate[] = [];
    public reportData: any[] = [];
    // public reportData: any = {};

    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public openReport: boolean = false;
    public DisplayAttachment: boolean = false;
    public reportName: string = '';
    public reportDisplayName: string = '';
    public reportType: string = '';
    public flag : string ="fromupdateoverallstatus";
    public isAdditonalSectionClicked: boolean = false;
    public isShowOverallCaseButtonClicked: boolean = false;

    public subscriptionList$: Subscription[] = [];

    public displayNotifybox: boolean = false;
    public notify: NotificationData = new NotificationData();

    public curItemsPerPage: number = 10;

    public FormProp: FormProperty = {
        'ColSize': 1,
        'CaptionBold': true,
        'IsEditable': true
        // 'ShowControlAlways': false
    };

    public FormConfig: any = {
        BaseUrl: environment.baseURL
    };

    public AttachPerm: any = {
        Upload: true,
        Download: false
    };

    public GridProp: any = {
        Editable: true,
        IsSortable: false
    };
    public GlobalConfig: any = {};

    public focusableEles!: HTMLElement[];
    public firstFocusableEle!: HTMLElement;
    public lastFocusableEle!: HTMLElement;
    constructor(private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
        , private _appSharedService: AppSharedService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);

    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {

            if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
                this.FormProp.IsEditable = false;
                this.GridProp.Editable = false;
                this.IsReadonly = true;
            }


            this.getUpdateOverallStatusTemplate();

        }
    }

    setDefaultPageFocus() {

        setTimeout(() => {
            this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
                input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
               button:not([disabled]), [tabindex="0"]`);
            this.focusableEles = Array.prototype.slice.call(this.focusableEles);

            if (this.focusableEles.length > 0) {
                this.firstFocusableEle = this.focusableEles[0];
                this.lastFocusableEle = this.focusableEles[this.focusableEles.length - 1];

                this.firstFocusableEle.focus();
            }

            //  let yesctrl = this.eleRef.nativeElement.querySelector('input[name="btnYes"]') as HTMLElement;
            //  if (!isNullOrUndefined(yesctrl)) {
            //      yesctrl.focus();
            //  }
            //  else {
            //      let noctrl = this.eleRef.nativeElement.querySelector('input[name="btnNo"]') as HTMLElement;
            //      if (!isNullOrUndefined(noctrl)) {
            //          noctrl.focus();
            //      }
            //  }
            //  let divele: HTMLDivElement = this.eleRef.nativeElement.querySelector('.modal-dialog');
            //  if (!isNullOrUndefined(divele)) {
            //      divele.addEventListener('keydown', (e) => { this.handleKeyDown(e); });
            //  }

        }, 50);
    }
    handleKeyDown(event: any) {
        let KEY_TAB = 9;
        let KEY_ESC = 27;

        switch (event.keyCode) {
            case KEY_TAB:
                if (this.focusableEles.length === 1) {
                    event.preventDefault();
                    break;
                }

                if (event.shiftKey) {
                    if (document.activeElement === this.firstFocusableEle) {
                        event.preventDefault();
                        this.lastFocusableEle.focus();
                    }
                }
                else {
                    if (document.activeElement === this.lastFocusableEle) {
                        event.preventDefault();
                        this.firstFocusableEle.focus();
                    }
                }
                break;
            // case KEY_ESC:
            //   this.close();
            //   break;
            default:
                break;
        }

    }

    onClick(event: any): void {
        let selitem: any = event.item;

        console.log(selitem);

        if (event.fieldname.toUpperCase() === 'ACTION') {

            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Upload

                    if (!isNullOrUndefined(this.reportData) && this.reportData.length > 0
                        && !isNullOrUndefined(this.reportData[0].FileName) && this.reportData[0].FileName !== '') {
                        this.deleteAttachment(true);
                    } else {
                        selitem.IsModified = 1;
                        selitem.IsRowHighlight = selitem.IsModified;
                        this.DisplayAttachment = true;
                    }
                    break;
            }
        }
        if (event.fieldname.toUpperCase() === 'FILEACTION') {

            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Download
                    this.downloadAttachment(selitem);

                    break;
            }
        }
    }
    downloadAttachment(data: any) {
        this.IsLoading = true;

        let odata: any = {
            'Module': data.FileModule,
            'Reference1': Number(this.RequestId),
            'Reference2': data.CheckId,
            'Reference3': 0,
            'Reference4': 0,
            'TransactionId': data.FileReferenceId,
            // 'FilePath': data.FilePath,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = data.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // this._toastr.success('Resume downloaded successfully', this.CompTitle);
                console.log('Resume downloaded successfully');

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Document downloaded successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;
        selitem.IsRowHighlight = selitem.IsModified;

        switch (event.fieldname.toUpperCase()) {
            case 'COLORCODE':
                if ((isNullOrUndefined(selitem.ColorCode) || selitem.ColorCode === '') && !isNullOrUndefined(selitem.OldColorCode) && selitem.OldColorCode !== '') {
                    // this.showNotificationMessage('Please select atleast one color code.', 2, '', null, '', '');
                    setTimeout(() => {
                        selitem.ColorCode = selitem.OldColorCode
                        selitem.ColorCodeDesc = selitem.OldColorCodeDesc
                        selitem.Comments = selitem.OldComments
                        selitem.IsModified = 0;
                        selitem.IsRowHighlight = selitem.IsModified;
                    }, 50);

                }
                break;
        }

        let eventdata = {
            fieldname: event.fieldname
        };

        this.updateOverallStatusOnChange.emit(eventdata);
    }

    getUpdateOverallStatusTemplate(): void {
        this.IsLoading = true;
        let odata: ComponentConfig = {
            Component: 'UpdateOverallStatus', ComponentCode: ComponentCodes.UpdateOverAllComponent
        };
        let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
            if (res !== undefined && res !== null && res.Status === 200) {
                this.updateOverallStatusTemplate = res.Data.FieldTemplate!;
                this.updateOverallStatusPermission = res.Data.Permission;
                this.setCompProperties(res.Data.Properties!);
                this.getCheckDocumentDetailsList(this.flag);

                //Action Permission
                this.updateOverallStatusPermission = JSON.parse(`["OverAllStatus_UpdateCase_Permission","OverAllStatus_SubmitInterimReport_Permission","OverAllStatus_SubmitUANReport_Permission","OverAllStatus_SubmitFinalReport_Permission","OverAllStatus_SubmitSupplementaryReport_Permission","OverAllStatus_SubmitCloserReport_Permission","OverAllStatus_StopCaseReport_Permission","OverAllStatus_InterimCancel_Permission","OverAllStatus_InterimSubmit_Permission","OverAllStatus_FinalCancel_Permission","OverAllStatus_FinalSubmit_Permission","OverAllStatus_SupplementaryCancel_Permission","OverAllStatus_SupplementarySubmit_Permission","OverAllStatus_CloserCancel_Permission","OverAllStatus_CloserSubmit_Permission","OverAllStatus_StopCancel_Permission","OverAllStatus_StopSubmit_Permission","OverAllStatus_UANCancel_Permission","OverAllStatus_UANSubmit_Permission"]`);

                this.updateOverallStatusProperties.UploadFileType = JSON.parse(`{
                    "UploadFileType": "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/jpeg,image/png,application/pdf"
                  }`);

                //Getting Template for Report Section
                this.reportTemplate = this.updateOverallStatusTemplate.filter(x => x.Group === 'Report');


                this.setDefaultPageFocus();
            }

            // this.IsLoading = false;
        },
            err => {
                this.IsLoading = false;
                console.log('Error on getUpdateOverallStatusTemplate.');
            });
        this.subscriptionList$.push(subscribe$);
    }

    setCompProperties(Properties: any[]) {
        if (!isNullOrUndefined(Properties) && Properties.length > 0) {

            // 2) Blocking Cols based on custom values
            this.updateOverallStatusProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

            // Other component specific props
            Properties.forEach(prop => {
                // avoiding generic props
                if (!isNullOrUndefined(prop.Name) &&
                    prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
                    && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
                    this.updateOverallStatusProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
                }
            });


        }
    }

    getCheckDocumentDetailsList(flag:string) {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': 0, 'flag':flag };

        let subscribe$: Subscription = this._requestService.getCheckDocumentDetailsList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.updateOverallStatusData = result.Data;

                this.updateOverallStatusData.forEach(item => {
                    if(item.DocumentStatusDesc!=null)
                    {
                        item.Comments = !isNullOrUndefined(item.ColorCode) && item.ColorCode !== '' ? item.Comments : '';
                    }
                    this.disableRows(item);
                });
            }
            // this.IsLoading = false;
            this.setActionButtonPermissions();
            this.IsLoading = false;

           // this.getOverALLStatusadditionalSectionList();

        },
            err => {
                console.log('Error on getting getCheckDocumentDetailsList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }


    UpdateOverallStatus() {
        //let modifieditems = this.updateOverallStatusData.filter(x => (x.DocumentStatusDesc!=null && x.IsModified === 1 && !isNullOrUndefined(x.ColorCode) && x.ColorCode !== '') || (x.DocumentStatusDesc==null && x.IsModified === 1));
        let modifieditems = this.updateOverallStatusData.filter(x => (x.DocumentStatusDesc!=null && x.IsEditable === 1 && !isNullOrUndefined(x.ColorCode) && x.ColorCode !== '') || (x.DocumentStatusDesc==null && x.IsEditable === 1));
        

        if(!isNullOrUndefined(modifieditems) && modifieditems.length>0)
            {
                let NAmodifiedchecks=modifieditems.filter(x=>x.DocumentStatusDesc==null);
                NAmodifiedchecks.forEach(NAmodifiedcheck=>{
                    NAmodifiedcheck.ColorCode='';
                });
            }
        this.IsLoading = true;
        let subscribe$: Subscription = this._requestService.UpdateOverallStatus(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.showNotificationMessage('Overall status updated successfully', 1, '', null, '', '');
                this.getCheckDocumentDetailsList(this.flag);

                this.refreshRequest();

            }
            this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // } else {
        //     this.showNotificationMessage('No rows are modified', false);
        // }
        // }
    }

    refreshRequest(): void {
        this.RefreshRequest.emit({ 'requestId': this.RequestId });
    }

    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            case 'SUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    this.submitReport();
                }
                break;

            case 'UPDATEOVERALLSTATUS':
                if (event.result.toUpperCase() === 'YES') {
                    this.UpdateOverallStatus();
                }
                break;

            case 'CANCELCASE':
                if (event.result.toUpperCase() === 'YES') {
                    if (!isNullOrUndefined(this.reportData[0].FileName) && this.reportData[0].FileName !== '') {
                        this.deleteAttachment(false);
                    }
                    else {
                        this.openReport = false;
                    }
                    // else if (!isNullOrUndefined(this.reportData[0].Comments) && this.reportData[0].Comments !== '') {
                    //     this.openReport = false;
                    // }

                }
                break;
            case 'STOPCASE':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    this.requestStopCase();
                }
                break;

        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }

    openReportSection(data: any): void {
        this.openReport = true;

        // Display Name and Filter name for buttons and bread scrum name display
        this.reportName = data.Name;
        this.reportDisplayName = data.DisplayName;

        //Assigning Report Type For Upload
        this.reportType = data.ReportId

        //Re-setting the Report Data

        this.reportData = [];

        let repData: ReportInfo = {
            RequestId: this.RequestId,
            ReportType: data.ReportId,
            Report: data.DisplayName,
            ActionCode: data.ActionCode,
            Action: '0',
            FileName: '',
            FilePath: '',
            FileReferenceId: 0,
            Comments: '',
            IsEditable: 1,
            IsModified: 0,
            IsSubmited: 1,
            RoleId: this._appSharedService.currentRoleData.RoleId
        };

        this.reportData.push(repData);
    }

    closeReportSection(): void {



        if (!isNullOrUndefined(this.reportData) && this.reportData.length > 0 && (this.reportData.filter(x => x.IsModified === 1)).length > 0) {
            // this.showCancelCaseConfirmation();
            this.showNotificationMessage('Are you sure you want to cancel the submission?', 2, 'CANCELCASE', null, 'Yes', 'No');

        } else {
            this.openReport = false;
        }

    }

    checkstopcaseValitidation() {

        if (isNullOrUndefined(this.reportData[0].FileName) || this.reportData[0].FileName === '') {
            this.showNotificationMessage('Please upload the file', 2, '', null, '', '');
        }
        else if (isNullOrUndefined(this.reportData[0].Comments) || this.reportData[0].Comments === '') {
            this.showNotificationMessage('Please enter comments', 2, '', null, '', '');
        }
        else {
            this.showStopCaseConfirmation();
        }
    }


    checkValitidation() {

        if (isNullOrUndefined(this.reportData[0].FileName) || this.reportData[0].FileName === '') {
            this.showNotificationMessage('Please upload the file', 2, '', null, '', '');
        }
        else if (isNullOrUndefined(this.reportData[0].Comments) || this.reportData[0].Comments === '') {
            this.showNotificationMessage('Please enter comments', 2, '', null, '', '');
                }
       
        else {
            this.showSubmitConfirmation();
        }
    }

    showSubmitConfirmation() {

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: 'Are you sure you want to submit ' + this.reportDisplayName + '?',
                yes: 'Submit now',
                no: 'Cancel',

                IsHeaderHidden: true,
                HeaderIcon: 'fa fa-check-circle text-success',
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: 'SUBMIT',
            item: null
        };

        this.displayNotifybox = true;
    }

    showStopCaseConfirmation() {

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: 'Are you sure you want to submit ' + this.reportDisplayName + '?',
                yes: 'Submit now',
                no: 'Cancel',

                IsHeaderHidden: true,
                HeaderIcon: 'fa fa-check-circle text-success',
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: 'STOPCASE',
            item: null
        };

        this.displayNotifybox = true;
    }

    submitReport() {

        // let oData: any = {
        //     'RequestId': this.RequestId,
        //     'ReportType': this.reportData[0].ReportId,
        //     'Filepath': this.reportData[0].FilePath,
        //     'FileName': this.reportData[0].FileName,
        //     'IsSubmited': 1,
        //     'ActionCode': this.reportData[0].ActionCode,
        //     'TransactionId': this.reportData[0].TransactionId
        // };

        if (this.reportData[0].Comments !== '' && this.reportData[0].Comments !== null && this.reportData[0].Comments !== undefined) {
            this.IsLoading = true;
            this.reportData[0].FilePath = '';
            let subscribe$: Subscription = this._requestService.submitReport(this.reportData[0]).subscribe(result => {
                if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                    this.showNotificationMessage(this.reportDisplayName + ' submitted successfully', 1, '', null, '', '');
                    // this.displayNotifybox = true;
                    this.refreshRequest();

                }
                this.IsLoading = false;
            },

                err => {
                    this.IsLoading = false;
                });
            this.subscriptionList$.push(subscribe$);
        } else {
            this.showNotificationMessage('Please provide comments.', 2, '', null, '', '')
        }
    }

    closeAttachment(event: any): void {
        console.log(event.data);

        if (!isNullOrUndefined(event) && event.Reload === true) {
            // this.reportData.FilePath = event.data[0].FilePath;
            // this.reportData.TransactionId = event.data[0].TransactionId;
            // this.reportData.FileName = event.data[0].FileName;

            this.reportData[0].FilePath = event.data[0].filePath;
            this.reportData[0].FileReferenceId = event.data[0].transactionId;
            this.reportData[0].FileName = event.data[0].fileName;
        } else {
            this.reportData[0].IsModified = 0;
            this.reportData[0].IsRowHighlight = this.reportData[0].IsModified;
        }

        this.DisplayAttachment = false;
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    actionButtonClick(data: any) {

        let modifieditems = this.updateOverallStatusData.filter(x => x.IsModified === 1);
        let valid: boolean = true;

        if (!isNullOrUndefined(data.ActionCode) && data.ActionCode !== '') {
            switch (data.ActionCode.toUpperCase()) {
                //Update Over all Status -- Update Case
                case 'AC001':
                    // this.UpdateOverallStatus();
                    // this.showUpdateCaseConfirmation();
                    //let modifiedwithcolorcode = this.updateOverallStatusData.filter(x =>(x.DocumentStatusDesc!=null && x.IsModified === 1 && !isNullOrUndefined(x.ColorCode) && x.ColorCode !== '')|| (x.DocumentStatusDesc==null && x.IsModified === 1));
                    let modifiedwithcolorcode = this.updateOverallStatusData.filter(x =>(x.DocumentStatusDesc!=null  && !isNullOrUndefined(x.ColorCode) && x.ColorCode !== '')|| (x.DocumentStatusDesc==null));
                    if (this.validateMandatoryFields(modifiedwithcolorcode)) {
                        this.showNotificationMessage('Are you sure you want to update the case?', 1, 'UPDATEOVERALLSTATUS', null, 'Yes', 'No');
                    }
                    break;

                //Open Interim Report                
                case 'AC003':
                    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {

                        let NoninsuffCommentsCheck = this.updateOverallStatusData.filter(x => x.UploadStatus === BGVStatus.Insufficiency_Not_Cleared && (isNullOrUndefined(x.Comments) || x.Comments === ''))
                        let GreenColorcodeCheck = this.updateOverallStatusData.filter(x => x.ColorCodeDesc !=="Green")

                        if (!isNullOrUndefined(NoninsuffCommentsCheck) && NoninsuffCommentsCheck.length > 0) {
                            this.showNotificationMessage('Please provide the comments for insufficiency non-clearance checks.', 2, '', null, '', '');
                          
                        } 
                         if (isNullOrUndefined(GreenColorcodeCheck) || ( GreenColorcodeCheck.length == 0))
                        {
                            this.showNotificationMessage('All checks/components are marked green, please upload Final green report.', 2, '', null, '', '');
                        } 
                        else {
                            this.openReportSection(data);
                        }

                    } else {
                        this.showNotificationMessage('Please update the modified rows and click interim report.', 2, '', null, '', '');
                    }
                    break;

                //Open Final Report
                case 'AC006':
                    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {

                        let NoninsuffCommentsCheck = this.updateOverallStatusData.filter(x => x.UploadStatus === BGVStatus.Insufficiency_Not_Cleared && (isNullOrUndefined(x.Comments) || x.Comments === ''))
                        if(this.RequestData.IsUANcheckMapping===1){
                            let flag: boolean = this.CheckUANsubmitted();
                            if(flag===false){
                                this.showNotificationMessage('Please submit the UAN report before submitting the final report.', 2, '', null, '', ''); 
                                break;
                            }
                        }
                        
                        if (!isNullOrUndefined(NoninsuffCommentsCheck) && NoninsuffCommentsCheck.length > 0) {
                            this.showNotificationMessage('Please provide the comments for insufficiency non-clearance checks.', 2, '', null, '', '');
                        } else {
                            this.openReportSection(data);
                        }                        

                        // this.openReportSection(data);
                    } else {
                        this.showNotificationMessage('Please update the modified rows and click Final report.', 2, '', null, '', '');
                    }
                    break;

                //Open Supplementary Report
                // case 'AC013':
                //     if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {


                //         let NoninsuffCommentsCheck = this.updateOverallStatusData.filter(x => x.UploadStatus === BGVStatus.Insufficiency_Not_Cleared && (isNullOrUndefined(x.Comments) || x.Comments === ''))

                //         if (!isNullOrUndefined(NoninsuffCommentsCheck) && NoninsuffCommentsCheck.length > 0) {
                //             this.showNotificationMessage('Please provide the comments for insufficiency non-clearance checks.', 2, '', null, '', '');
                //         } else {
                //             this.openReportSection(data);
                //         }

                //         // this.openReportSection(data);
                //     } else {
                //         this.showNotificationMessage('Please update the modified rows and click Supplementary report.', 2, '', null, '', '');
                //     }
                //     break;

                //Open Stop Case Report
                case 'AC016':
                    this.openReportSection(data);

                    break;

                    //Open UAN Report                
                case 'AC039':
                    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {

                        let NoninsuffCommentsCheck = this.updateOverallStatusData.filter(x => x.UploadStatus === BGVStatus.Insufficiency_Not_Cleared && (isNullOrUndefined(x.Comments) || x.Comments === ''))
                        let GreenColorcodeCheck = this.updateOverallStatusData.filter(x => x.ColorCodeDesc !=="Green")

                        if (!isNullOrUndefined(NoninsuffCommentsCheck) && NoninsuffCommentsCheck.length > 0) {
                            this.showNotificationMessage('Please provide the comments for insufficiency non-clearance checks.', 2, '', null, '', '');
                          
                        }                          
                        else {
                            this.openReportSection(data);
                        }

                    } else {
                        this.showNotificationMessage('Please update the modified rows and click UAN report.', 2, '', null, '', '');
                    }
                    break;

            }
        }

        if (!isNullOrUndefined(data.DisplayName) && data.DisplayName !== '') {
            if (data.Group === 'StopCaseReport') {
                switch (data.DisplayName.toUpperCase()) {
                    //Update Report
                    case 'SUBMIT REPORT':
                        this.checkstopcaseValitidation();
                        //this.showStopCaseConfirmation();
                        break;

                    //Close Report Section
                    case 'CANCEL':
                        this.closeReportSection();
                        break;
                }

            } else {
                switch (data.DisplayName.toUpperCase()) {
                    //Update Report
                    case 'SUBMIT REPORT':
                        this.checkValitidation();
                        break;

                    //Close Report Section
                    case 'CANCEL':
                        this.closeReportSection();
                        break;
                }
            }
        }


    }

    deleteAttachment(reUpload: boolean) {

        if (this.reportData[0].FileName !== '' && !isNullOrUndefined(this.reportData[0].FileName)) {

            this.IsLoading = true;

            let odata: object = {
                'Module': this.reportData[0].ReportType,
                'Reference1': Number(this.RequestId),
                'Reference2': 0,
                'Reference3': 0,
                'Reference4': 0,
                'TransactionId': this.reportData[0].FileReferenceId,
                // 'FilePath': this.reportData[0].FilePath,
                'FilePath': '',
                'FileName': this.reportData[0].FileName,
                'UserId': this._appSharedService.UserAccessInfo.actinguserid
            };

            let deleteData: any[] = [];
            deleteData.push(odata);

            let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
                if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                }
                this.IsLoading = false;

                if (reUpload === false) {
                    this.openReport = false;
                    this.refreshRequest();
                } else {
                    this.reportData[0].FileReferenceId = 0;
                    this.reportData[0].FileName = '';
                    this.reportData[0].Comments = '';
                    this.DisplayAttachment = true;
                }
            },

                err => {
                    this.IsLoading = false;
                });
            this.subscriptionList$.push(subscribe$);
        }
    }

    setActionButtonPermissions(): void {
        if (!isNullOrUndefined(this.updateOverallStatusProperties.ActionButtons) && this.updateOverallStatusProperties.ActionButtons.length > 0) {

            this.updateOverallStatusProperties.ActionButtons.forEach((item: any) => {

                if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                    item.HasPermission = false;
                    item.IsApplicable = false;
                    let peritem = this.updateOverallStatusPermission.indexOf('OverAllStatus_' + item.Name + '_Permission');
                    if (peritem >= 0) {
                        item.HasPermission = true;
                    }
                    switch (item.Name.toUpperCase()) {
                        case 'UPDATECASE':
                            item.IsApplicable = this.enableUpdateCase();
                            break;
                        case 'INTERIMCANCEL':
                        case 'INTERIMSUBMIT':
                        case 'SUBMITINTERIMREPORT':
                            item.IsApplicable = this.enableSubmitInterimReport();
                            break;
                        case 'FINALCANCEL':
                        case 'FINALSUBMIT':
                        case 'SUBMITFINALREPORT':
                            item.IsApplicable = this.enableFinalReport();
                            break;
                        case 'SUPPLEMENTARYCANCEL':
                        case 'SUPPLEMENTARYSUBMIT':
                        case 'SUBMITSUPPLEMENTARYREPORT':
                            item.IsApplicable = this.enableSupplementaryReport();
                            break;
                        case 'CLOSERCANCEL':
                        case 'CLOSERSUBMIT':
                        case 'SUBMITCLOSERREPORT':
                            item.IsApplicable = this.enableCloserReport();
                            break;

                        case 'STOPCANCEL':
                        case 'STOPSUBMIT':
                        case 'STOPCASEREPORT':
                            item.IsApplicable = this.enableStopCase();
                            break;
                        case 'UANCANCEL':
                        case 'UANSUBMIT':                        
                        case 'SUBMITUANREPORT':
                            item.IsApplicable=this.enableSubmitUANReport();
                            break;    
                    }
                }
            });
        }
    }

    enableUpdateCase(): boolean {
        let flag: boolean = false;
        if (!isNullOrUndefined(this.updateOverallStatusData) && this.updateOverallStatusData.length > 0
            && this.updateOverallStatusData.filter((x: any) => x.IsEditable === 1).length > 0
            && this.updateOverallStatusData[0].EnableUpdateCase === 1
            && this.RequestData.IsStopCaseSubmitted === false
            && this.RequestData.IsStopCase === false
        ) {
            flag = true;
        }
        return flag;
    }

    enableSubmitInterimReport(): boolean {
        let flag: boolean = false;
        if (this.RequestData.EnableInterim === true && this.RequestData.IsStopCaseSubmitted === false
            // && this.updateOverallStatusData.filter((x: any) => x.IsEditable === 1).length > 0
            && this.RequestData.IsStopCase === false
        ) {
            flag = true;
        }
        return flag;
    }

    enableFinalReport(): boolean {
        let flag: boolean = false;
        if (this.RequestData.EnableFinal === true && this.RequestData.IsStopCaseSubmitted === false
            // && this.updateOverallStatusData.filter((x: any) => x.IsEditable === 1).length > 0
            && this.RequestData.IsStopCase === false
        ) {
            flag = true;
        }
        return flag;
    }

    enableSupplementaryReport(): boolean {
        
        let flag: boolean = false;
        if (this.RequestData.EnableSupplementary === true && this.RequestData.IsStopCaseSubmitted === false
            // && this.updateOverallStatusData.filter((x: any) => x.IsEditable === 1).length > 0
            && this.RequestData.IsStopCase === false
        ) {
            flag = true;
        }
        return flag;
    }

    enableCloserReport(): boolean {
        let flag: boolean = false;
        if (this.RequestData.EnableClosure === true && this.RequestData.IsStopCaseSubmitted === false
            && this.RequestData.IsStopCase === false
        ) {
            flag = true;
        }
        return flag;
    }

    enableStopCase(): boolean {
        let flag: boolean = false;
        if (
            this.RequestData.IsStopCaseSubmitted === true
            && this.RequestData.IsStopCase === false
            && (this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorAdmin || this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorMember)
        ) {
            flag = true;
        }
        return flag;
    }
    enableSubmitUANReport(): boolean {
        let flag: boolean = false;
        //this.RequestData.EnableInterim === true && this.RequestData.IsStopCaseSubmitted === false)||
        //(this.RequestData.EnableFinal === true && this.RequestData.IsStopCaseSubmitted === false)
        if(this.RequestData.IsUANcheckMapping===1){
        if ((this.RequestData.EnableUAN === true && this.RequestData.IsStopCaseSubmitted === false)
            // && this.updateOverallStatusData.filter((x: any) => x.IsEditable === 1).length > 0
            && this.RequestData.IsStopCase === false 
        ) {
            flag = true;
        }
    }
        return flag;
    }
    CheckUANsubmitted(): boolean {
        let flag: boolean = false;
        if(this.RequestData.IsUANcheckMapping===1){
        if (this.RequestData.IsUANreportsubmit != 0) {
            flag = true;
        }}
        return flag;
    }
    // checkCellRequired(fieldname: string, item: CheckInfo, parent: UpdateOverallStatusComponent): boolean {
    //     let bRequired = false;

    //     if (!isNullOrUndefined(parent.updateOverallStatusProperties.CommentsValidation)) {
    //         parent.updateOverallStatusProperties.CommentsValidation.forEach((element: any) => {
    //             if ((fieldname.toUpperCase() === element.Field) &&
    //                 item.ColorCode === element.Value &&
    //                 (isNullOrUndefined(item.Comments) || item.Comments === '')) {
    //                 bRequired = true;
    //             }
    //         });
    //     }

    //     return bRequired;
    // }

    validateMandatoryFields(modifieditems: CheckInfo[]): boolean {
        let valid: boolean = true;

        if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
            this.showNotificationMessage('No checks are modified to update the case.', 2, '', null, '', '');
            valid = false;
        }

        if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {

            let valColorCode = modifieditems.filter(x => (x.DocumentStatusDesc!=null && x.ColorCode === '' || isNullOrUndefined(x.ColorCode) && (!isNullOrUndefined(x.Comments) && x.Comments !== '') ));

            if (!isNullOrUndefined(valColorCode) && valColorCode.length > 0) {
                this.showNotificationMessage('Please provide color code for modified rows.', 2, '', null, '', '');
                valid = false;
            }

            let emptyComments = modifieditems.filter(x => (x.Comments === '' || isNullOrUndefined(x.Comments) && (!isNullOrUndefined(x.ColorCode) && x.ColorCode !== ''&&x.IsApplicable!=false)));

            if (!isNullOrUndefined(emptyComments) && emptyComments.length > 0) {
                this.showNotificationMessage('Please provide comments for modified rows.', 2, '', null, '', '');
                valid = false;
            }                       
        }

        return valid;
    }

    pageItemsChange() {

        if (this.curItemsPerPage > 0) {
            this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
        }
        else {
            this.GridProp.PaginationConfig.ItemsPerPage = 100;
        }


        // reload grid data
        // if (isNullOrUndefined(this.searchEvent.item.Value) || this.searchEvent.item.Value.length == 0) {
        //   this.SearchData(this.searchEvent, true, false);
        // }
        // else {
        //   this.SearchData(this.searchEvent, false, true);
        // }


    }

    actButtonClick(event: any) {
        switch (event.buttonname.toUpperCase()) {
            case 'InterimReport':
                // this.exportToExcel();
                break;

        }
    }

    getOverALLStatusadditionalSectionList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': null };

        let subscribe$: Subscription = this._requestService.getActivityLogList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.updateOverallAdditionalSectionData = result.Data;
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getQCadditionalSectionList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    requestStopCase() {

        this.IsLoading = true;

        let odata: any = {
            RequestId: this.RequestId,
            RequestStatus: this.RequestData.RequestStatus,
            Comments: this.reportData[0].Comments
        };

        let subscribe$: Subscription = this._requestService.requestStopCase(odata).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Stop case report submitted successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;

                this.refreshRequest();

                // this._toastr.success('Request stopped successfully', this.CompTitle);
            }
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
                // this._toastr.warning(result.Message, this.CompTitle);
                console.log(result.Message)

            }
            // this.IsLoading = false;

        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    disableRows(item: any) {
        if (!isNullOrUndefined(this.updateOverallStatusProperties.BlockCols) && this.updateOverallStatusProperties.BlockCols.length > 0) {
            this.updateOverallStatusProperties.BlockCols.forEach((blockitem: any) => {
                if (blockitem.Value === item[blockitem.Field] && item.IsShowCauseNotice === false && blockitem.Field!='IsApplicable') {
                    // blockitem.BlockCols.forEach((item: any) => {
                    item.IsEditable = 0;
                    // });
                }
                // if(blockitem.Field=='IsApplicable' && blockitem.Value==false)
                //     {
                //         item.IsEditable = 1; 
                //     }
            });
        }
    }

    showOverallStatus()
    {
        if(this.isShowOverallCaseButtonClicked)
        {
            this.isAdditonalSectionClicked=false;
            this.isShowOverallCaseButtonClicked=false;
        }
        
        else{
            this.getOverALLStatusadditionalSectionList();
            this.isAdditonalSectionClicked=true;
            this.isShowOverallCaseButtonClicked=true;
        }

    }
}
