import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from './report.service';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridComponent } from 'src/modules/grid/grid.component';
import { FilterMaster } from './report.interface';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html'
})

@AutoUnsubscribe
export class ReportComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public compTitle: string = 'Report';
  public selectedView: string = 'dashboard';

  public SearchTemplate: FieldTemplate[] = [];
  public SearchButtons: any = [];
  public SearchTemplate_SLA: FieldTemplate[] = [];
  public SearchTemplate_VendorSLA: FieldTemplate[] = [];
  public SearchTemplate_VendorReport: FieldTemplate[] = [];
  public SearchTemplate_UserwiseaudittrailReport: FieldTemplate[] = [];
  public slaReportTemplate: FieldTemplate[] = [];
  public vendorSlaReportTemplate: FieldTemplate[] = [];
  public vendorReportTemplate: FieldTemplate[] = [];
  public userwiseaudittrailReportTemplate: FieldTemplate[] = [];
  public FilterReportTemplate: FieldTemplate[] = [];
  public reportProperty: any = [];
  public reportPermissions: any = [];
  public FilterData: any = {};
  public AuditTrailFilterData: any={};
  public testdata:any=[];

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public repListTemplate: FieldTemplate[] = [];
  public repListData: any[] = [];
  public repListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };

  public selectedInfoData: any;

  public curItemsPerPage: number = 10;

  public GridProp: any = {
    Editable: true,
    TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }

  
  public Config: any = {
    ActionButtons: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  public searchEvent: any = { 'item': {}, 'action': 'Clear' };

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;


  constructor(private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private reportService: ReportService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {
    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;
    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.selectedView = this._route.snapshot.data['selectTab'];
      // this.Config.ActionButtons = JSON.parse(`[{"Name":"ExportToExcel","Icon":"fa fa-file-excel-o","DisplayName":"Dow<u>n</u>load Excel","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Filter","Icon":"fa fa-filter","DisplayName":"F<u>i</u>lter","AccessKey":"I","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);
      //   this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
      this.FilterData = new FilterMaster();
      this.SearchButtons = JSON.parse(`[
        {
           "Name":"Search",
           "CustomClass":"rounded-pill btnCustom btnPrimary small",
           "DisplayName":"Search",
           "AccessKey":"N",
           "ShowArrow":true,
           "IsApplicable":true,
           "HasPermission":true,
           "IsEnabled":true,
           "Group": "Reports",
           "Confirmation":{
              "IsEnabled":false,
              "Message":null,
              "Action":null
           },
           "SeqOrder":1
        },
        {
           "Name":"Clear",
           "CustomClass":"rounded-pill btnCustom btnNegative small",
           "DisplayName":"Clear",
           "AccessKey":"S",
           "ShowArrow":false,
           "IsApplicable":true,
           "HasPermission":true,
           "IsEnabled":true,
           "Group": "Reports",
           "Confirmation":{
              "IsEnabled":false,
              "Message":null,
              "Action":null
           },
           "SeqOrder":2
        }

      ]`);
      this.getMasterReportTemplate();
      // switch (this.selectedView.toUpperCase()) {
      //   case 'MASTERREPORT':
      //     this.getMasterReportTemplate();
      //     break;
      //   case 'SLAREPORT':
      //     this.slaReportTemplate = JSON.parse(`[
      //       {
      //         "FieldName": "RequestId",
      //         "DisplayName": "Case ID",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "number",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": "false",
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "CandidateId",
      //         "DisplayName": "Candidate ID",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "number",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": "false",
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "EmployeeId",
      //         "DisplayName": "Employee ID",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "number",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": "false",
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Name",
      //         "DisplayName": "Name",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "BGVType",
      //         "DisplayName": "Hire Type",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "BGVStatus",
      //         "DisplayName": "BGV Status",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "CaseCreatedDate",
      //         "DisplayName": "Case Created Date",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "datepicker",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": "true",
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "VendorAssignedDate",
      //         "DisplayName": "Vendor Assigned Date",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "datepicker",
      //           "InputType": null,
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": "true",
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "InitiationSLADuration",
      //         "DisplayName": "Initiation SLA Duration",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "ClosureSLADuration",
      //         "DisplayName": "Closure SLA Duration",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "L1InsufficiencyDuration",
      //         "DisplayName": "L1 Insufficiency Duration",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "L2InsufficiencyDuration",
      //         "DisplayName": "L2 Insufficiency Duration",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "L3InsufficiencyDuration",
      //         "DisplayName": "L3 Insufficiency Duration",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "CaseStoppedDate",
      //         "DisplayName": "Case Stopped Date",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "datepicker",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": "true",
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       }
      //     ]`);
      //     this.SearchTemplate_SLA = JSON.parse(`[
      //       {
      //         "FieldName": "CandidateId",
      //         "DisplayName": "Candidate ID",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "Source": {},
      //           "DynamicLoad": false
      //         },
      //         "Events": "",
      //         "EditValidate": {},
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       },
      //       {
      //         "FieldName": "EmployeeId",
      //         "DisplayName": "Employee ID",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "Source": {},
      //           "DynamicLoad": false
      //         },
      //         "Events": "",
      //         "EditValidate": {},
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       },
      //       {
      //         "FieldName": "Name",
      //         "DisplayName": "Candidate Name",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "Source": {},
      //           "DynamicLoad": false
      //         },
      //         "Events": "",
      //         "EditValidate": {},
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       },
      //       {
      //         "FieldName": "Hiretype",
      //         "DisplayName": "Hire Type (Fresher/Lateral)",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "dropdown",
      //           "Source": {
      //             "Url": "Report/GetReportMasterData?codeType=HiringType",
      //             "KeyField": "Code",
      //             "ValueField": "CodeDescription"
      //           },
      //           "DependentControl": [
      //             "Value"
      //           ],
      //           "DynamicLoad": false
      //         },
      //         "Events": "",
      //         "EditValidate": {},
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       },
      //       {
      //         "FieldName": "BGVStatus",
      //         "DisplayName": "BGV Status",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "dropdown",
      //           "Source": {
      //             "Url": "Report/GetReportMasterData?CodeType=BGVStatus",
      //             "KeyField": "Code",
      //             "ValueField": "CodeDescription"
      //           },
      //           "DependentControl": [
      //             "Value"
      //           ],
      //           "DynamicLoad": false
      //         },
      //         "Events": "",
      //         "EditValidate": {},
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       },
      //       {
      //         "FieldName": "VendorAssignedDate",
      //         "DisplayName": "Vendor Assigned Date",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "datepicker",
      //           "Source": {},
      //           "Format": "true",
      //           "DynamicLoad": false,
      //           "LoadedUrl": []
      //         },
      //         "Events": "",
      //         "EditValidate": {
      //           "Min": null,
      //           "Max": null
      //         },
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       },
      //       {
      //         "FieldName": "LastMonth",
      //         "DisplayName": "Last Month",
      //         "ColumnWidth": "20",
      //         "Cssnames": "",
      //         "Style": "",
      //         "Editable": true,
      //         "IsHidden": false,
      //         "RowMerge": false,
      //         "IsColResizable": true,
      //         "ResponsiveMediaSize": "md,lg,xl",
      //         "ShowControlAlways": false,
      //         "Control": {
      //           "Type": "dropdown",
      //           "Source": {
      //             "Url": "Report/GetReportMasterData?codeType=LastMonth",
      //             "KeyField": "Code",
      //             "ValueField": "CodeDescription"
      //           },
      //           "DependentControl": [
      //             "Value"
      //           ],
      //           "DynamicLoad": false,
      //           "LoadedUrl": [
      //             {
      //               "Url": "Report/GetReportMasterData?codeType=LastMonth",
      //               "List": [
      //                 {
      //                   "TransactionId": 0,
      //                   "CodeType": "LastMonth",
      //                   "Code": "1",
      //                   "CodeDescription": "Last One month",
      //                   "UserDef1": null,
      //                   "UserDef2": null,
      //                   "UserDef3": null,
      //                   "UserDef4": null,
      //                   "UserDef5": null,
      //                   "UserDef6": null,
      //                   "UserDef7": null,
      //                   "DisplayOrder": null,
      //                   "RowStatus": null,
      //                   "CreatedBy": null,
      //                   "CreatedDate": "0001-01-01T00:00:00Z",
      //                   "UpdatedBy": null,
      //                   "UpdatedDate": "0001-01-01T00:00:00Z",
      //                   "CreatedFromIP": null
      //                 },
      //                 {
      //                   "TransactionId": 0,
      //                   "CodeType": "LastMonth",
      //                   "Code": "2",
      //                   "CodeDescription": "Last Two months",
      //                   "UserDef1": null,
      //                   "UserDef2": null,
      //                   "UserDef3": null,
      //                   "UserDef4": null,
      //                   "UserDef5": null,
      //                   "UserDef6": null,
      //                   "UserDef7": null,
      //                   "DisplayOrder": null,
      //                   "RowStatus": null,
      //                   "CreatedBy": null,
      //                   "CreatedDate": "0001-01-01T00:00:00Z",
      //                   "UpdatedBy": null,
      //                   "UpdatedDate": "0001-01-01T00:00:00Z",
      //                   "CreatedFromIP": null
      //                 },
      //                 {
      //                   "TransactionId": 0,
      //                   "CodeType": "LastMonth",
      //                   "Code": "3",
      //                   "CodeDescription": "Last Three months",
      //                   "UserDef1": null,
      //                   "UserDef2": null,
      //                   "UserDef3": null,
      //                   "UserDef4": null,
      //                   "UserDef5": null,
      //                   "UserDef6": null,
      //                   "UserDef7": null,
      //                   "DisplayOrder": null,
      //                   "RowStatus": null,
      //                   "CreatedBy": null,
      //                   "CreatedDate": "0001-01-01T00:00:00Z",
      //                   "UpdatedBy": null,
      //                   "UpdatedDate": "0001-01-01T00:00:00Z",
      //                   "CreatedFromIP": null
      //                 },
      //                 {
      //                   "TransactionId": 0,
      //                   "CodeType": "LastMonth",
      //                   "Code": "4",
      //                   "CodeDescription": "Last Four months",
      //                   "UserDef1": null,
      //                   "UserDef2": null,
      //                   "UserDef3": null,
      //                   "UserDef4": null,
      //                   "UserDef5": null,
      //                   "UserDef6": null,
      //                   "UserDef7": null,
      //                   "DisplayOrder": null,
      //                   "RowStatus": null,
      //                   "CreatedBy": null,
      //                   "CreatedDate": "0001-01-01T00:00:00Z",
      //                   "UpdatedBy": null,
      //                   "UpdatedDate": "0001-01-01T00:00:00Z",
      //                   "CreatedFromIP": null
      //                 },
      //                 {
      //                   "TransactionId": 0,
      //                   "CodeType": "LastMonth",
      //                   "Code": "5",
      //                   "CodeDescription": "Last Five months",
      //                   "UserDef1": null,
      //                   "UserDef2": null,
      //                   "UserDef3": null,
      //                   "UserDef4": null,
      //                   "UserDef5": null,
      //                   "UserDef6": null,
      //                   "UserDef7": null,
      //                   "DisplayOrder": null,
      //                   "RowStatus": null,
      //                   "CreatedBy": null,
      //                   "CreatedDate": "0001-01-01T00:00:00Z",
      //                   "UpdatedBy": null,
      //                   "UpdatedDate": "0001-01-01T00:00:00Z",
      //                   "CreatedFromIP": null
      //                 },
      //                 {
      //                   "TransactionId": 0,
      //                   "CodeType": "LastMonth",
      //                   "Code": "6",
      //                   "CodeDescription": "Last Six months",
      //                   "UserDef1": null,
      //                   "UserDef2": null,
      //                   "UserDef3": null,
      //                   "UserDef4": null,
      //                   "UserDef5": null,
      //                   "UserDef6": null,
      //                   "UserDef7": null,
      //                   "DisplayOrder": null,
      //                   "RowStatus": null,
      //                   "CreatedBy": null,
      //                   "CreatedDate": "0001-01-01T00:00:00Z",
      //                   "UpdatedBy": null,
      //                   "UpdatedDate": "0001-01-01T00:00:00Z",
      //                   "CreatedFromIP": null
      //                 }
      //               ]
      //             }
      //           ],
      //           "List": [
      //             {
      //               "TransactionId": 0,
      //               "CodeType": "LastMonth",
      //               "Code": "1",
      //               "CodeDescription": "Last One month",
      //               "UserDef1": null,
      //               "UserDef2": null,
      //               "UserDef3": null,
      //               "UserDef4": null,
      //               "UserDef5": null,
      //               "UserDef6": null,
      //               "UserDef7": null,
      //               "DisplayOrder": null,
      //               "RowStatus": null,
      //               "CreatedBy": null,
      //               "CreatedDate": "0001-01-01T00:00:00Z",
      //               "UpdatedBy": null,
      //               "UpdatedDate": "0001-01-01T00:00:00Z",
      //               "CreatedFromIP": null
      //             },
      //             {
      //               "TransactionId": 0,
      //               "CodeType": "LastMonth",
      //               "Code": "2",
      //               "CodeDescription": "Last Two months",
      //               "UserDef1": null,
      //               "UserDef2": null,
      //               "UserDef3": null,
      //               "UserDef4": null,
      //               "UserDef5": null,
      //               "UserDef6": null,
      //               "UserDef7": null,
      //               "DisplayOrder": null,
      //               "RowStatus": null,
      //               "CreatedBy": null,
      //               "CreatedDate": "0001-01-01T00:00:00Z",
      //               "UpdatedBy": null,
      //               "UpdatedDate": "0001-01-01T00:00:00Z",
      //               "CreatedFromIP": null
      //             },
      //             {
      //               "TransactionId": 0,
      //               "CodeType": "LastMonth",
      //               "Code": "3",
      //               "CodeDescription": "Last Three months",
      //               "UserDef1": null,
      //               "UserDef2": null,
      //               "UserDef3": null,
      //               "UserDef4": null,
      //               "UserDef5": null,
      //               "UserDef6": null,
      //               "UserDef7": null,
      //               "DisplayOrder": null,
      //               "RowStatus": null,
      //               "CreatedBy": null,
      //               "CreatedDate": "0001-01-01T00:00:00Z",
      //               "UpdatedBy": null,
      //               "UpdatedDate": "0001-01-01T00:00:00Z",
      //               "CreatedFromIP": null
      //             },
      //             {
      //               "TransactionId": 0,
      //               "CodeType": "LastMonth",
      //               "Code": "4",
      //               "CodeDescription": "Last Four months",
      //               "UserDef1": null,
      //               "UserDef2": null,
      //               "UserDef3": null,
      //               "UserDef4": null,
      //               "UserDef5": null,
      //               "UserDef6": null,
      //               "UserDef7": null,
      //               "DisplayOrder": null,
      //               "RowStatus": null,
      //               "CreatedBy": null,
      //               "CreatedDate": "0001-01-01T00:00:00Z",
      //               "UpdatedBy": null,
      //               "UpdatedDate": "0001-01-01T00:00:00Z",
      //               "CreatedFromIP": null
      //             },
      //             {
      //               "TransactionId": 0,
      //               "CodeType": "LastMonth",
      //               "Code": "5",
      //               "CodeDescription": "Last Five months",
      //               "UserDef1": null,
      //               "UserDef2": null,
      //               "UserDef3": null,
      //               "UserDef4": null,
      //               "UserDef5": null,
      //               "UserDef6": null,
      //               "UserDef7": null,
      //               "DisplayOrder": null,
      //               "RowStatus": null,
      //               "CreatedBy": null,
      //               "CreatedDate": "0001-01-01T00:00:00Z",
      //               "UpdatedBy": null,
      //               "UpdatedDate": "0001-01-01T00:00:00Z",
      //               "CreatedFromIP": null
      //             },
      //             {
      //               "TransactionId": 0,
      //               "CodeType": "LastMonth",
      //               "Code": "6",
      //               "CodeDescription": "Last Six months",
      //               "UserDef1": null,
      //               "UserDef2": null,
      //               "UserDef3": null,
      //               "UserDef4": null,
      //               "UserDef5": null,
      //               "UserDef6": null,
      //               "UserDef7": null,
      //               "DisplayOrder": null,
      //               "RowStatus": null,
      //               "CreatedBy": null,
      //               "CreatedDate": "0001-01-01T00:00:00Z",
      //               "UpdatedBy": null,
      //               "UpdatedDate": "0001-01-01T00:00:00Z",
      //               "CreatedFromIP": null
      //             }
      //           ]
      //         },
      //         "Events": "",
      //         "EditValidate": {
      //           "Required": true
      //         },
      //         "Group": "SearchFilter",
      //         "DataCell": {}
      //       }
      //     ]`);
      //     //  this.SearchDataSLAReport(this.searchEvent);
      //     break;


      //   // {"FieldName":"Vendor","DisplayName":"Select","LabelTemplate":null,"LinkedField":"SelectDesc","ColumnWidth":"20","Control":{"Type":"dropdown","InputType":null,"List":null,"Source":{"Url":"Report/GetReportMasterData?codeType=Vendor","KeyField":"Code","ValueField":"CodeDescription","UrlAttribute":null,"Target":null},"DependentControl":["Value"],"Format":null,"LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":1,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchFilter","ResponsiveMediaSize":"md,lg,xl"},
      //   case 'VENDORSLAREPORT':
      //     this.vendorSlaReportTemplate = JSON.parse(`[
      //       {
      //         "FieldName": "Vendor",
      //         "DisplayName": "Vendor Name",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Month",
      //         "DisplayName": "Month",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Year",
      //         "DisplayName": "Year",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "CasesCreated",
      //         "DisplayName": "Total No of cases created",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "CarriedForward",
      //         "DisplayName": "Total No of cases carried forwarded from previous month",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Initiated",
      //         "DisplayName": "Total No of cases initiated",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "InitiatedPer",
      //         "DisplayName": "Percentage of cases initiated",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Stopped",
      //         "DisplayName": "Total No of cases stopped",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Stoppedin24",
      //         "DisplayName": "Total No of cases stopped with in 24 Hours",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Stopped24to72",
      //         "DisplayName": "Total No of cases stopped after 24 hours and with in 72 hours",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "StoppedAfter72",
      //         "DisplayName": "Total No of cases stopped after 72 Hours",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Rejected",
      //         "DisplayName": "Total No of cases rejected by QC/NonGreen",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "RejectedPer",
      //         "DisplayName": "Percentage of cases rejected by QC/NonGreen",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "Closed",
      //         "DisplayName": "Total No of cases closed",
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "WithInSLA",
      //         "DisplayName": "Total No of cases closed with in SLA",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "WithInSLA1to3",
      //         "DisplayName": "Total No of cases closed with in SLA (> =1 day and <= 3 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "WithInSLA3to5",
      //         "DisplayName": "Total No of cases closed with in SLA (> 3 days and <= 5 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "WithInSLAAfter5",
      //         "DisplayName": "Total No of cases closed with in SLA (> 5 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "ExceededSLA",
      //         "DisplayName": "Total No of closed cases exceeded SLA",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "ExceededSLA1to10",
      //         "DisplayName": "Total No of closed cases exceeded SLA (>=1 day and <=10 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "ExceededSLA10to20",
      //         "DisplayName": "Total No of closed cases exceeded SLA (>=1 day and <=10 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "ExceededSLA20to30",
      //         "DisplayName": "Total No of closed cases exceeded SLA (>20 day and <=30 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "ExceededSLAAfter30",
      //         "DisplayName": "Total No of closed cases exceeded SLA (>30 days)",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "IncentiveAmount",
      //         "DisplayName": "Incentive Amount",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "PenaltyAmount",
      //         "DisplayName": "Penalty Amount",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "TotalAmount",
      //         "DisplayName": "Total Amount",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       },
      //       {
      //         "FieldName": "TotalBillableAmount",
      //         "DisplayName": "Total Billable Amount",
      //         "LabelTemplate": null,
      //         "LinkedField": null,
      //         "ColumnWidth": "20",
      //         "Control": {
      //           "Type": "textbox",
      //           "InputType": "text",
      //           "List": null,
      //           "Source": {
      //             "Url": null,
      //             "KeyField": null,
      //             "ValueField": null,
      //             "UrlAttribute": null,
      //             "Target": null
      //           },
      //           "DependentControl": null,
      //           "Format": null,
      //           "LoadedUrl": null,
      //           "DynamicLoad": false
      //         },
      //         "Cssnames": "",
      //         "Style": "",
      //         "Events": "",
      //         "Editable": 0,
      //         "IsHidden": false,
      //         "ToolTipTemplate": null,
      //         "EditValidate": {
      //           "Required": false,
      //           "Min": null,
      //           "Max": null
      //         },
      //         "FieldCollection": null,
      //         "Group": "SearchResult",
      //         "ResponsiveMediaSize": "md,lg,xl"
      //       }
      //     ]`);
      //     this.SearchTemplate_VendorSLA = JSON.parse(`[
      //     {"FieldName":"Vendor","DisplayName":"Vendor Name","LabelTemplate":null,"LinkedField":"SelectDesc","ColumnWidth":"20","Control":{"Type":"dropdown","InputType":null,"List":null,"Source":{"Url":"Report/GetReportMasterData?codeType=Vendor","KeyField":"Code","ValueField":"CodeDescription","UrlAttribute":null,"Target":null},"DependentControl":["Value"],"Format":null,"LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":1,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchFilter","ResponsiveMediaSize":"md,lg,xl"},
      //     {
      //        "FieldName":"Month",
      //        "DisplayName":"Month",
      //        "ColumnWidth":"20",
      //        "Cssnames":"",
      //        "Style":"",
      //        "Editable":true,
      //        "IsHidden":false,
      //        "RowMerge":false,
      //        "IsColResizable":true,
      //        "ResponsiveMediaSize":"md,lg,xl",
      //        "ShowControlAlways":false,
      //        "Control":{
      //           "Type":"dropdown",
      //           "Source":{
      //              "Url":"",
      //              "KeyField":"Code",
      //              "ValueField":"CodeDescription"
      //           },
      //           "DependentControl":[
      //              "Value"
      //           ],
      //           "DynamicLoad":false,
      //           "LoadedUrl":[
      //              {
      //                 "Url":"",
      //                 "List":[
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"1",
      //                       "CodeDescription":"JANUARY",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"2",
      //                       "CodeDescription":"FEBUARY",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"3",
      //                       "CodeDescription":"MARCH",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"4",
      //                       "CodeDescription":"APRIL",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"5",
      //                       "CodeDescription":"MAY",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"6",
      //                       "CodeDescription":"JUNE",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"7",
      //                       "CodeDescription":"JULY",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"8",
      //                       "CodeDescription":"AUGUST",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"9",
      //                       "CodeDescription":"SEPTEMBER",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"10",
      //                       "CodeDescription":"OCTOBER",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"11",
      //                       "CodeDescription":"NOVEMBER",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Month",
      //                       "Code":"12",
      //                       "CodeDescription":"DECEMBER",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    },
      //                    {
      //                     "TransactionId":0,
      //                     "CodeType":"Month",
      //                     "Code":"0",
      //                     "CodeDescription":"ALL",
      //                     "UserDef1":null,
      //                     "UserDef2":null,
      //                     "UserDef3":null,
      //                     "UserDef4":null,
      //                     "UserDef5":null,
      //                     "UserDef6":null,
      //                     "UserDef7":null,
      //                     "DisplayOrder":null,
      //                     "RowStatus":null,
      //                     "CreatedBy":null,
      //                     "CreatedDate":"0001-01-01T00:00:00Z",
      //                     "UpdatedBy":null,
      //                     "UpdatedDate":"0001-01-01T00:00:00Z",
      //                     "CreatedFromIP":null
      //                  }
      //                 ]
      //              }
      //           ],
      //           "List":[
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"1",
      //                 "CodeDescription":"JANUARY",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"2",
      //                 "CodeDescription":"FEBUARY",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"3",
      //                 "CodeDescription":"MARCH",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"4",
      //                 "CodeDescription":"APRIL",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"5",
      //                 "CodeDescription":"MAY",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"6",
      //                 "CodeDescription":"JUNE",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"7",
      //                 "CodeDescription":"JULY",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"8",
      //                 "CodeDescription":"AUGUST",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"9",
      //                 "CodeDescription":"SEPTEMBER",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"10",
      //                 "CodeDescription":"OCTOBER",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"11",
      //                 "CodeDescription":"NOVEMBER",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Month",
      //                 "Code":"12",
      //                 "CodeDescription":"DECEMBER",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              },
      //              {
      //               "TransactionId":0,
      //               "CodeType":"Month",
      //               "Code":"0",
      //               "CodeDescription":"ALL",
      //               "UserDef1":null,
      //               "UserDef2":null,
      //               "UserDef3":null,
      //               "UserDef4":null,
      //               "UserDef5":null,
      //               "UserDef6":null,
      //               "UserDef7":null,
      //               "DisplayOrder":null,
      //               "RowStatus":null,
      //               "CreatedBy":null,
      //               "CreatedDate":"0001-01-01T00:00:00Z",
      //               "UpdatedBy":null,
      //               "UpdatedDate":"0001-01-01T00:00:00Z",
      //               "CreatedFromIP":null
      //            }
      //           ]
      //        },
      //        "Events":"",
      //        "EditValidate":{
      //           "Required":true
      //        },
      //        "Group":"SearchFilter",
      //        "DataCell":{

      //        }
      //     },
      //     {
      //        "FieldName":"Year",
      //        "DisplayName":"Year",
      //        "ColumnWidth":"20",
      //        "Cssnames":"",
      //        "Style":"",
      //        "Editable":true,
      //        "IsHidden":false,
      //        "RowMerge":false,
      //        "IsColResizable":true,
      //        "ResponsiveMediaSize":"md,lg,xl",
      //        "ShowControlAlways":false,
      //        "Control":{
      //           "Type":"dropdown",
      //           "Source":{
      //              "Url":"",
      //              "KeyField":"Code",
      //              "ValueField":"CodeDescription"
      //           },
      //           "DependentControl":[
      //              "Value"
      //           ],
      //           "DynamicLoad":false,
      //           "LoadedUrl":[
      //              {
      //                 "Url":"",
      //                 "List":[
      //                    {
      //                       "TransactionId":0,
      //                       "CodeType":"Year",
      //                       "Code":"2022",
      //                       "CodeDescription":"2022",
      //                       "UserDef1":null,
      //                       "UserDef2":null,
      //                       "UserDef3":null,
      //                       "UserDef4":null,
      //                       "UserDef5":null,
      //                       "UserDef6":null,
      //                       "UserDef7":null,
      //                       "DisplayOrder":null,
      //                       "RowStatus":null,
      //                       "CreatedBy":null,
      //                       "CreatedDate":"0001-01-01T00:00:00Z",
      //                       "UpdatedBy":null,
      //                       "UpdatedDate":"0001-01-01T00:00:00Z",
      //                       "CreatedFromIP":null
      //                    }
      //                 ]
      //              }
      //           ],
      //           "List":[
      //              {
      //                 "TransactionId":0,
      //                 "CodeType":"Year",
      //                 "Code":"2022",
      //                 "CodeDescription":"2022",
      //                 "UserDef1":null,
      //                 "UserDef2":null,
      //                 "UserDef3":null,
      //                 "UserDef4":null,
      //                 "UserDef5":null,
      //                 "UserDef6":null,
      //                 "UserDef7":null,
      //                 "DisplayOrder":null,
      //                 "RowStatus":null,
      //                 "CreatedBy":null,
      //                 "CreatedDate":"0001-01-01T00:00:00Z",
      //                 "UpdatedBy":null,
      //                 "UpdatedDate":"0001-01-01T00:00:00Z",
      //                 "CreatedFromIP":null
      //              }
      //           ]
      //        },
      //        "Events":"",
      //        "EditValidate":{
      //           "Required":true
      //        },
      //        "Group":"SearchFilter",
      //        "DataCell":{

      //        }
      //     }
      //  ]`)
      //     //  this.SearchDataVendorSLAReport(this.searchEvent)

      //     break;

      // }

    }
  }

  getMasterReportTemplate(): void {
    this.IsLoading = true;

    this.SearchTemplate = [];
    this.repListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Report', ComponentCode: ComponentCodes.ReportComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.reportProperty = res.Data.Properties;
        this.reportPermissions = res.Data.Permission;
        this.SearchTemplate = template.filter(x => x.Group === 'SearchFilter');
        this.SearchTemplate_SLA = template.filter(x => x.Group === 'MasterSLAReport_SearchFilter');
        this.SearchTemplate_VendorSLA = template.filter(x => x.Group === 'VendorSLAReport_SearchFilter');
        this.SearchTemplate_VendorReport = template.filter(x => x.Group === 'VendorReport_SearchFilter');
        this.SearchTemplate_UserwiseaudittrailReport = template.filter(x => x.Group === 'UserwiseaudittrailReport_SearchFilter');
        console.log(this.SearchTemplate)
        this.repListTemplate = template.filter(x => x.Group === 'SearchResult');
        this.slaReportTemplate = template.filter(x => x.Group === 'MasterSLAReportResult');
        this.vendorSlaReportTemplate = template.filter(x => x.Group === 'VendorReportResult');
        this.vendorReportTemplate = template.filter(x => x.Group === 'VendorSearchResult');
        this.userwiseaudittrailReportTemplate = template.filter(x => x.Group === 'UserauditReportSearchResult');
        this.setCompProperties(res.Data.Properties!);
        ///////commented////
        //    this.Config.ActionButtons = 
        //this.reportProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'requestreportbuttons')
        // JSON.parse((this.reportProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'requestreportbuttons'.toLowerCase())[0].JSONData.toString()) : []);
        this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

        // this.reportProperty.filter(x => x.Name.toLowerCase() === 'reportdatabuttons'.toLowerCase()).length > 0 ?
        // JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'requestreportbuttons'.toLowerCase())[0].JSONData.toString()) : []);
        //  this.reportProperty.ReportDataButtons.filter((x: any) => x.Group === 'Reports');
        if (!isNullOrUndefined(this.reportProperty)) {
          this.reportProperty.forEach((item: any) => {
            item.HasPermission = false;
            let peritem = this.reportPermissions.indexOf('ReportDataButtons_Permission');
            if (peritem >= 0) {
              item.HasPermission = true;
            }
          });


        }

        if (!isNullOrUndefined(this.reportProperty)) {
          this.reportProperty.forEach((item: any) => {
            item.HasPermission = false;
            let peritem = this.reportPermissions.indexOf('ReportSearchButtons_Permission');
            if (peritem >= 0) {
              item.HasPermission = true;
            }
          });


        }
      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getMasterReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    }
  }

  SearchData(event: any): void {

    console.log(event)

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' )
    {
      if(isNullOrUndefined(event.item.FromDate) && (isNullOrUndefined(event.item.ToDate))
      && (isNullOrUndefined(event.item.CandidateId)) && (isNullOrUndefined(event.item.CandidateName)) && (isNullOrUndefined(event.item.AccountName))
      && (isNullOrUndefined(event.item.Hiretype)) && (isNullOrUndefined(event.item.BGVStatus)) && (isNullOrUndefined(event.item.VendorName)) && 
      (isNullOrUndefined(event.item.VendorcaserefNo)) && (isNullOrUndefined(event.item.InitiationDate)) &&  (isNullOrUndefined(event.item.Entity)) )

      {

        this.notify = {
          info: {
            header: 'Search Alert',
            message: 'Please select atleast one filter to proceed with Search.',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-exclamation-circle text-warning',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;

        return;
      } 


      else if((!isNullOrUndefined(event.item.FromDate) && (isNullOrUndefined(event.item.ToDate))) || (isNullOrUndefined(event.item.FromDate) && !(isNullOrUndefined(event.item.ToDate))))
  
        {
  
          this.notify = {
            info: {
              header: 'Search Alert',
              message: 'Kindly enter both From Date & To Date to proceed with Search.',
              yes: '',
              no: '',
  
              IsHeaderHidden: true,
              HeaderIcon: 'fa fa-exclamation-circle text-warning',
              IsCrossEnable: true,
              SizeClass: 'modal-sm'
            },
  
            action: '',
            item: null
          };
  
          this.displayNotifybox = true;
  
          return;
        }
  }


    this.searchEvent = event;

    this.IsLoading = true;

    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;

    this.FilterData = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      this.FilterData = {};
      this.IsLoading = false;
      return;
    }

    let subscribe$: Subscription = this.reportService.getMasterReportDetails(this.FilterData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        
        this.repListData = result.Data;
        console.log('DATA',this.repListData);
        this.dataTotalCount = this.repListData.length;

        this.repListDataALL = this.repListData;
        console.log('ALLDATA',this.repListData);
        this.testdata=this.repListData;
        this.repListData.forEach((item) => {
          item.Action = '0,1';
          item.IsEditable = 1;
        });

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }

  //////for sla report////



  SearchDataSLAReport(event: any): void {
    this.repListData = [];
    this.searchEvent = [];
    console.log(event)
    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
      (isNullOrUndefined(event.item.LastMonth) || event.item.LastMonth.trim().length == 0)) {
      //  alert("Please select both to proceed search")

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please select last month value to proceed with Search.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }


    this.searchEvent = event;
    console.log(this.searchEvent);
    this.IsLoading = true;

    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;

    this.FilterData = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      this.FilterData = {};
      this.repListData = [];
      this.repListDataALL = [];
      this.dataTotalCount = 0;
      this.IsLoading = false;
      return;
    }

    let subscribe$: Subscription = this.reportService.getSLAReportDetails(this.FilterData).subscribe(result => {
      console.log("result---------->");
      console.log(result);

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.repListData = result.Data;
        console.log('data',this.repListData);
        this.dataTotalCount = this.repListData.length;

        this.repListDataALL = this.repListData;
        console.log('all data',this.repListDataALL)

        this.repListData.forEach((item) => {
          item.Action = '0,1';
          item.IsEditable = 1;
        });

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }
  ////////for vendor sla report////
  SearchDataVendorSLAReport(event: any): void {
    this.searchEvent=[];
    console.log(event)
    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH') {
      if (isNullOrUndefined(event.item.Month) || isNullOrUndefined(event.item.Year)) {

        //  alert("Please select both to proceed search")

        this.notify = {
          info: {
            header: 'Search Alert',
            message: 'Please select Month and Year value to proceed with Search.',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-exclamation-circle text-warning',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;
        return;

      }
    }

    

    this.searchEvent = event;

    this.IsLoading = true;

    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;

    this.FilterData = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      this.FilterData = {};
      this.repListData = [];
      this.repListDataALL = [];
      this.dataTotalCount = 0;
      this.IsLoading = false;
      return;
    }

    let subscribe$: Subscription = this.reportService.getVendorReportDetails(this.FilterData).subscribe(result => {
      console.log("result---------->");
      console.log(result);

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.repListData = result.Data;
        console.log(this.repListData);
        this.dataTotalCount = this.repListData.length;

        this.repListDataALL = this.repListData;

        this.repListData.forEach((item) => {
          item.Action = '0,1';
          item.IsEditable = 1;
        });

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }

  ////////for new vendor  report////
  SearchDataVendorReport(event: any): void {
    this.searchEvent=[];
    console.log(event)
    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH') {
      if (isNullOrUndefined(event.item.FromDate) || isNullOrUndefined(event.item.ToDate)) {

        //  alert("Please select both to proceed search")

        this.notify = {
          info: {
            header: 'Search Alert',
            message: 'Please select from date and to date value to proceed with Search.',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-exclamation-circle text-warning',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;
        return;

      }
    }

    

    this.searchEvent = event;

    this.IsLoading = true;

    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;

    this.FilterData = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      this.FilterData = {};
      this.repListData = [];
      this.repListDataALL = [];
      this.dataTotalCount = 0;
      this.IsLoading = false;
      return;
    }

    let subscribe$: Subscription = this.reportService.getVendor_BGVReportDetails(this.FilterData).subscribe(result => {
      // console.log("result---------->");
      // console.log(result);

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.repListData = result.Data;
        console.log(this.repListData);
        this.dataTotalCount = this.repListData.length;

        this.repListDataALL = this.repListData;

        this.repListData.forEach((item) => {
          item.Action = '0,1';
          item.IsEditable = 1;
        });

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 120);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }


  ////////for User Audit  report////
  SearchDataUserAuditReport(event: any): void {
    this.searchEvent = [];
    console.log(event);

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH') {
      if (isNullOrUndefined(event.item.UserId) || isNullOrUndefined(event.item.UserName) || isNullOrUndefined(event.item.FromDate) || isNullOrUndefined(event.item.ToDate)) {

        //  alert("Please select both to proceed search")

        this.notify = {
          info: {
            header: 'Search Alert',
            message: 'Please select All values to proceed with Search.',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-exclamation-circle text-warning',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;
        return;

      } 
      if (isNullOrUndefined(event.item.FromDate)) {
            event.item.FromDate = new Date().toISOString().split('T')[0]; // Set FromDate to today's date
        }

        if (isNullOrUndefined(event.item.ToDate)) {
            // Show popup if ToDate is not provided
            this.notify = {
                info: {
                    header: 'Search Alert',
                    message: 'Please select ToDate value to proceed with Search.',
                    yes: '',
                    no: '',
                    IsHeaderHidden: true,
                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                    IsCrossEnable: true,
                    SizeClass: 'modal-sm'
                },
                action: '',
                item: null
            };
            this.displayNotifybox = true;
            return;
        }
        
        const fromDate = new Date(event.item.FromDate);
        const toDate = new Date(event.item.ToDate);
        const diffMonths = (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (toDate.getMonth() - fromDate.getMonth());

        if (diffMonths > 1 || (diffMonths === 1 && toDate.getDate() > fromDate.getDate())) {
            // Show popup if date difference is more than one month
            this.notify = {
                info: {
                    header: 'Search Alert',
                    message: 'The date range should not exceed one month.',
                    yes: '',
                    no: '',
                    IsHeaderHidden: true,
                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                    IsCrossEnable: true,
                    SizeClass: 'modal-sm'
                },
                action: '',
                item: null
            };
            this.displayNotifybox = true;
            return;
        }
    }
this.searchEvent = event;

    this.IsLoading = true;

    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;

    this.FilterData = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
        this.FilterData = {};
        this.repListData = [];
        this.repListDataALL = [];
        this.dataTotalCount = 0;
        this.IsLoading = false;
        return;
    }

    let subscribe$: Subscription = this.reportService.getUserAuditReportDetails(this.FilterData).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            this.repListData = result.Data;
            console.log(this.repListData);
            this.dataTotalCount = this.repListData.length;

            this.repListDataALL = this.repListData;

            this.repListData.forEach((item) => {
                item.Action = '0,1';
                item.IsEditable = 1;
            });

            setTimeout(() => {
                if (!isNullOrUndefined(this.overallgrid)) {
                    this.overallgrid.Pagination.CurrentPage = 1;
                }
            }, 120);
        }
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
            // this._toastr.warning(result.Message, this.CompTitle);
        }

        this.IsLoading = false;
    },
    err => {
        this.IsLoading = false;
    });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

}
  pageItemsChange() {
    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.SearchData(this.searchEvent);
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  exportToExcel() {
    
    switch (this.selectedView.toUpperCase()) {
      case 'MASTERREPORT':

        if (this.repListData.length === 0) {
          // this.toastr.warning("Report Data is empty");
        }
        else {
          this.IsLoading = true;

          let month = new Date().getMonth() + 1;
          this.ExcelFileName = 'BGV-Master-Report ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

          let tempdata = [];
      //     let itr=0;
      //  while(itr<this.repListData.length)
      //  {
      
      //   let temp2 = (this.repListData[itr].RevisedMostRecentHireDate).split('T');
      //   this.repListData[itr].RevisedMostRecentHireDate=temp2;
      //  // this.repListData[itr].RevisedMostRecentHireDate = this.convert(this.repListData[itr].RevisedMostRecentHireDate)
       
      //  itr++;
      //  }
       this.repListData.forEach((item) => {
       item.DateofInitiationtoVendor =isNullOrUndefined(item.DateofInitiationtoVendor)? 'NA'  :new Date(item.DateofInitiationtoVendor); 
          item.RevisedMostRecentHireDate =isNullOrUndefined(item.RevisedMostRecentHireDate)? 'NA' :new Date(item.RevisedMostRecentHireDate);
        // item.PHVCompletionDate =new Date(isNullOrUndefined(item.PHVCompletionDate)? 'NA' :item.PHVCompletionDate);
        // item.RevisedMostRecentHireDate =isNullOrUndefined(item.RevisedMostRecentHireDate)? 'NA' :item.RevisedMostRecentHireDate='01-Jan-1970'?'NA' :item.RevisedMostRecentHireDate='01-Jan-1900'? 'NA' :new Date(item.RevisedMostRecentHireDate);
        //  item.RevisedMostRecentHireDate =!item.RevisedMostRecentHireDate || item.RevisedMostRecentHireDate.toLocaleString() == '01-Jan-1970' || item.RevisedMostRecentHireDate == '01-Jan-1900' ? 'NA' : new Date(item.RevisedMostRecentHireDate);                                                                                                           
        item.PHVCompletionDate =isNullOrUndefined(item.PHVCompletionDate)? 'NA' :new Date(item.PHVCompletionDate);
        // item.PHVCompletionDate =!item.PHVCompletionDate || item.PHVCompletionDate =='01-Jan-1970' || item.PHVCompletionDate == '01-Jan-1900' || item.PHVCompletionDate == '02-Jan-1970' || item.PHVCompletionDate == '02-Jan-1900'? 'NA' : new Date(item.PHVCompletionDate);
        item.InitiationDate =!item.InitiationDate || item.InitiationDate === '01-Jan-1970' || item.InitiationDate === '01-Jan-1900' ? 'NA' : new Date(item.InitiationDate);
        item.InterimDate =!item.InterimDate || item.InterimDate === '01-Jan-1970' || item.InterimDate === '01-Jan-1900' ? 'NA' : new Date(item.InterimDate);
        item.FinalReportDate =!item.FinalReportDate || item.FinalReportDate === '01-Jan-1970' || item.FinalReportDate === '01-Jan-1900' ? 'NA' : new Date(item.FinalReportDate);
        item.SupplementaryDate =!item.SupplementaryDate || item.SupplementaryDate === '01-Jan-1970' || item.SupplementaryDate === '01-Jan-1900' ? 'NA' : new Date(item.SupplementaryDate);
        item.FirstInsufficiencyRaisedDate =!item.FirstInsufficiencyRaisedDate || item.FirstInsufficiencyRaisedDate === '01-Jan-1970' || item.FirstInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.FirstInsufficiencyRaisedDate);
        item.FirstInsufficiencyClosureDate =!item.FirstInsufficiencyClosureDate || item.FirstInsufficiencyClosureDate === '01-Jan-1970' || item.FirstInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.FirstInsufficiencyClosureDate);
        item.LastInsufficiencyRaisedDate =!item.LastInsufficiencyRaisedDate || item.LastInsufficiencyRaisedDate === '01-Jan-1970' || item.LastInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.LastInsufficiencyRaisedDate);
        item.LastInsufficiencyClosureDate =!item.LastInsufficiencyClosureDate || item.LastInsufficiencyClosureDate === '01-Jan-1970' || item.LastInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.LastInsufficiencyClosureDate);
        item.EducationInsufficiencyRaisedDate =!item.EducationInsufficiencyRaisedDate || item.EducationInsufficiencyRaisedDate === '01-Jan-1970' || item.EducationInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.EducationInsufficiencyRaisedDate);
        item.EducationInsufficiencyClosureDate =!item.EducationInsufficiencyClosureDate || item.EducationInsufficiencyClosureDate === '01-Jan-1970' || item.EducationInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.EducationInsufficiencyClosureDate);
        item.PInsufficiencyRaisedDate =!item.PInsufficiencyRaisedDate || item.PInsufficiencyRaisedDate === '01-Jan-1970' || item.PInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.PInsufficiencyRaisedDate);
        item.PInsufficiencyClosureDate =!item.PInsufficiencyClosureDate || item.PInsufficiencyClosureDate === '01-Jan-1970' || item.PInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.PInsufficiencyClosureDate);
        item.P1toP5InsufficiencyRaisedDate =!item.P1toP5InsufficiencyRaisedDate || item.P1toP5InsufficiencyRaisedDate === '01-Jan-1970' || item.P1toP5InsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.P1toP5InsufficiencyRaisedDate); 
        item.P1toP5InsufficiencyClosureDate =!item.P1toP5InsufficiencyClosureDate || item.P1toP5InsufficiencyClosureDate === '01-Jan-1970' || item.P1toP5InsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.P1toP5InsufficiencyClosureDate);
        item.IRFBVFInsufficiencyRaisedDate =!item.IRFBVFInsufficiencyRaisedDate || item.IRFBVFInsufficiencyRaisedDate === '01-Jan-1970' || item.IRFBVFInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.IRFBVFInsufficiencyRaisedDate);
        item.IRFBVFInsufficiencyClosureDate =!item.IRFBVFInsufficiencyClosureDate || item.IRFBVFInsufficiencyClosureDate === '01-Jan-1970' || item.IRFBVFInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.IRFBVFInsufficiencyClosureDate);
		    item.CourtInsufficiencyRaisedDate	 =!item.CourtInsufficiencyRaisedDate || item.CourtInsufficiencyRaisedDate === '01-Jan-1970' || item.CourtInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.CourtInsufficiencyRaisedDate);
		    item.CourtInsufficiencyClosureDate	 =!item.CourtInsufficiencyClosureDate || item.CourtInsufficiencyClosureDate === '01-Jan-1970' || item.CourtInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.CourtInsufficiencyClosureDate);
		    item.DatabaseInsufficiencyRaisedDate =!item.DatabaseInsufficiencyRaisedDate || item.DatabaseInsufficiencyRaisedDate === '01-Jan-1970' || item.DatabaseInsufficiencyRaisedDate === '01-Jan-1900' ? 'NA' : new Date(item.DatabaseInsufficiencyRaisedDate);
		    item.DatabaseInsufficiencyClosureDate=!item.DatabaseInsufficiencyClosureDate || item.DatabaseInsufficiencyClosureDate === '01-Jan-1970' || item.DatabaseInsufficiencyClosureDate === '01-Jan-1900' ? 'NA' : new Date(item.DatabaseInsufficiencyClosureDate);
        item.ReportUploadDate=isNullOrUndefined(item.ReportUploadDate)?item.ReportUploadDate:item.ReportUploadDate.toLocaleString();
        item.CompletedDate=isNullOrUndefined(item.CompletedDate)?item.CompletedDate:item.CompletedDate.toLocaleString();
        item.DocumentUpdatedDate=!item.DocumentUpdatedDate || item.DocumentUpdatedDate === '01-Jan-1970' || item.DocumentUpdatedDate === '01-Jan-1900' ? 'NA' : new Date(item.DocumentUpdatedDate);
        item.YearofPassing=!item.YearofPassing || item.YearofPassing === '01-Jan-1970' || item.YearofPassing === '01-Jan-1900' ? 'NA' : new Date(item.YearofPassing)
      });
         
          // tempdata.push({ Name: 'Master Data', Data: this.repListData });
          tempdata.push({
            Name: 'Master Data',
            Data: this.repListData,
            ColumnList: (!isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ?
              this.Config.DownloadColumns.map((x: any) => x.FieldName) : null),
            FieldTemplate: this.repListTemplate
          });

          this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

          this.IsLoading = false;
        }
        break;
      case 'SLAREPORT':

        if (this.repListData.length === 0) {
          // this.toastr.warning("Report Data is empty");
        }
        else {
          this.IsLoading = true;

          let month = new Date().getMonth() + 1;
          this.ExcelFileName = 'BGV-Master-SLA-Report ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

          console.log("---------before this.repListData---------")
          console.log(this.repListData)

          // let updatedrepListData = this.repListData.map(obj =>
          //   obj.CaseCreatedDate && obj.VendorAssignedDate ? {
          //     ...obj, CaseCreatedDate: obj.CaseCreatedDate.getDate().toString().padStart(2, '0') + "-" + (obj.CaseCreatedDate.getMonth() + 1).toString().padStart(2, '0') + "-" + obj.CaseCreatedDate.getFullYear() + " " + obj.CaseCreatedDate.getHours().toString().padStart(2, '0') + ":" + obj.CaseCreatedDate.getMinutes().toString().padStart(2, '0'), VendorAssignedDate: obj.VendorAssignedDate.getDate().toString().padStart(2, '0') + "-" + (obj.VendorAssignedDate.getMonth() + 1).toString().padStart(2, '0') + "-" + obj.VendorAssignedDate.getFullYear() + " " + obj.VendorAssignedDate.getHours().toString().padStart(2, '0') + ":" + obj.VendorAssignedDate.getMinutes().toString().padStart(2, '0')
          //   } : obj

          // );
          // console.log("---------after this.repListData---------")
       //  console.log(updatedrepListData)

          let updatedrepListData :any = this.repListData;

          for (let i = 0; i < updatedrepListData.length; i++) {
            delete updatedrepListData[i].CellLock;
            delete updatedrepListData[i].IsEditable;
            delete updatedrepListData[i]. Action
          }

          // for (let i = 0; i < updatedrepListData.length; i++) {
          //   delete updatedrepListData[i].IsEditable;
          // }

          

          let tempdata = [];
          // tempdata.push({ Name: 'Master Data', Data: this.repListData });
          console.log(this.Config.DownloadColumns);
          console.log(this.slaReportTemplate);
          tempdata.push({
            Name: 'Master SLA Data',
            Data: updatedrepListData,
            ColumnList: (!isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ?
              this.Config.DownloadColumns.map((x: any) => x.FieldName) : null),
            FieldTemplate: this.slaReportTemplate
          });
         
          this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

          this.IsLoading = false;
        }
        break;

      case 'VENDORSLAREPORT':

        if (this.repListData.length === 0) {
          // this.toastr.warning("Report Data is empty");
        }
        else {
          this.IsLoading = true;
          let month = new Date().getMonth() + 1;
          this.ExcelFileName = 'BGV-Vendor-SLA-Report ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

          let tempdata = [];
          // tempdata.push({ Name: 'Master Data', Data: this.repListData });
          tempdata.push({
            Name: 'Vendor SLA Data',
            Data: this.repListData,
            ColumnList: (!isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ?
              this.Config.DownloadColumns.map((x: any) => x.FieldName) : null),
            FieldTemplate: this.vendorSlaReportTemplate
          });
          console.log(tempdata)

          this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

          this.IsLoading = false;
        }
        break;
       

        case 'VENDORREPORT':

        if (this.repListData.length === 0) {
          // this.toastr.warning("Report Data is empty");
        }
        else {
          this.IsLoading = true;
          let month = new Date().getMonth() + 1;
          this.ExcelFileName = 'BGV-Vendor-Report ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

          let tempdata = [];
          // tempdata.push({ Name: 'Master Data', Data: this.repListData });
          tempdata.push({
            Name: 'Vendor Report Data',
            Data: this.repListData,
            ColumnList: (!isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ?
              this.Config.DownloadColumns.map((x: any) => x.FieldName) : null),
            FieldTemplate: this.vendorReportTemplate
          });
          console.log(tempdata)

          this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

          this.IsLoading = false;
        }
        break;

        case 'USERWISEAUDITTRAILREPORT':

        if (this.repListData.length === 0) {
          // this.toastr.warning("Report Data is empty");
        }
        else {
          this.IsLoading = true;
          let month = new Date().getMonth() + 1;
          this.ExcelFileName = 'BGV-UserAudit-Report ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

          let tempdata = [];
          // tempdata.push({ Name: 'Master Data', Data: this.repListData });
          tempdata.push({
            Name: 'UserAudit Data',
            Data: this.repListData,
            ColumnList: (!isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ?
              this.Config.DownloadColumns.map((x: any) => x.FieldName) : null),
            FieldTemplate: this.userwiseaudittrailReportTemplate
          });
          console.log(tempdata)

          this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

          this.IsLoading = false;
        }
        break;





    }

  }

  
  showFilter(event?: any): void {
    this.FilterReportTemplate=[];
    switch (this.selectedView.toUpperCase()) {
      case 'MASTERREPORT':
        this.FilterReportTemplate = this.repListTemplate;
        break;
      case 'SLAREPORT':
        this.FilterReportTemplate = this.slaReportTemplate;
        break;
      case 'VENDORSLAREPORT':
        this.FilterReportTemplate = this.vendorSlaReportTemplate;
        break;
      case 'VENDORREPORT':
        this.FilterReportTemplate = this.vendorReportTemplate;
        break;
        case 'USERWISEAUDITTRAILREPORT':
          this.FilterReportTemplate = this.userwiseaudittrailReportTemplate;
          break;

    }
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    console.log(this.repListDataALL)
    console.log(this.repListData)
    if (event.sCondn.length > 0) {
      this.repListData = asEnumerable(this.repListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.repListData.length;
    }
    else {
      this.repListData = this.repListDataALL;
      this.dataTotalCount = this.repListData.length;
    }

  }

  onClick(event: any): void {
    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'CANDIDATEID':
      case 'CANDIDATENAME':
        this._router.navigate(['app/home/search/case', selitem.RequestId]);
        break;
    }
  }

  convert(str:string) {
    var date = new Date(str),
     // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       month = date.toLocaleString('default', { month: 'short' }),
       day = ("0" + date.getDate()).slice(-2);
    return [day,month,date.getFullYear() ].join("-");
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'STOPCASE':
        if (event.result.toUpperCase() === 'YES') {
          // this.requestStopCase();
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }
}

