<!-- Master Report Component -->
 
<ng-container *ngIf="selectedView == 'masterreport'">
    <!-- <nav aria-label="breadcrumb" class="breadcrumbSection">
      <div class="d-flex w-100 justify-content-between">
        <ul class="breadcrumb m-0 flex-fill">
          <li class="breadcrumb-item"><a href="index.html">Report</a></li>
          <li class="breadcrumb-item active" aria-current="page">Master Report</li>
        </ul>     
      </div>
    </nav> -->
    <div class="contentScroll p-2">
      <ng-container *ngIf="(SearchTemplate != null && SearchTemplate != undefined && SearchTemplate.length > 0)">
        <searchby [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
          [FormProp]="FormProp" (SearchData)="SearchData($event)" class="customForm">
        </searchby>
      </ng-container>
  
      <div class="contentScroll p-2 simpleCard">
        <div class="card h-100 m-0">
          <div class="card-header"
            *ngIf="(searchEvent.item !=null && searchEvent.item != undefined && 
                searchEvent.item.LastMonth !=null && searchEvent.item.LastMonth != undefined && searchEvent.item.LastMonth.length > 0)">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>
          <div class="card-body overflow-auto">
            <!-- <div class="gridAction">
              <div class="d-flex justify-content-between">
                <label class="d-flex">
                  <span class="pe-2">Show</span>
                  <select class="form-select form-select-sm align-self-center" name="complex-table1_length"
                    aria-controls="complex-table1" [(ngModel)]="curItemsPerPage" (change)="pageItemsChange()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option> -->
                    <!-- <option value=-1>All</option> -->
                  <!-- </select>
                  <span class="ps-2">entries</span>
                </label> -->
<!--   
                <div class="col-auto flex-fill">
                  <actionbar [actionList]="Config.ActionButtons" (butClick)="actButtonClick($event)"
                    [noActionClass]="true">
                  </actionbar>
                </div>
              </div>
            </div> -->
  
           <ng-container *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
              <grid-dispctrl #overallgrid [FieldTemplate]="repListTemplate" [GridData]="repListData" [GridProp]="GridProp"
                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
              </grid-dispctrl>
            </ng-container>
  
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-center">
              <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    </div>
  </ng-container>





  <!----SLA Report-->

  <ng-container *ngIf="selectedView == 'slareport'">
    <!-- <nav aria-label="breadcrumb" class="breadcrumbSection">
      <div class="d-flex w-100 justify-content-between">
        <ul class="breadcrumb m-0 flex-fill">
          <li class="breadcrumb-item"><a href="index.html">Report</a></li>
          <li class="breadcrumb-item active" aria-current="page">Master Report</li>
        </ul>     
      </div>
    </nav> -->
    <div class="contentScroll p-2">
      <ng-container *ngIf="(SearchTemplate_SLA != null && SearchTemplate_SLA != undefined && SearchTemplate_SLA.length > 0)">
        <searchby  [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate_SLA" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
          [FormProp]="FormProp" (SearchData)="SearchDataSLAReport($event)" class="customForm">
        </searchby>
      </ng-container>
  
      <div class="contentScroll p-2 simpleCard">
        <div class="card h-100 m-0">
          <div class="card-header"
            *ngIf="(searchEvent.item !=null && searchEvent.item != undefined)">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>
          <div class="card-body overflow-auto">
            <!-- <div class="gridAction">
              <div class="d-flex justify-content-between">
                <label class="d-flex">
                  <span class="pe-2">Show</span>
                  <select class="form-select form-select-sm align-self-center" name="complex-table1_length"
                    aria-controls="complex-table1" [(ngModel)]="curItemsPerPage" (change)="pageItemsChange()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option> -->
                    <!-- <option value=-1>All</option> -->
                  <!-- </select>
                  <span class="ps-2">entries</span>
                </label> -->
<!--   
                <div class="col-auto flex-fill">
                  <actionbar [actionList]="Config.ActionButtons" (butClick)="actButtonClick($event)"
                    [noActionClass]="true">
                  </actionbar>
                </div>
              </div>
            </div> -->
  
           <ng-container *ngIf="(slaReportTemplate != null && slaReportTemplate != undefined && slaReportTemplate.length > 0)">
              <grid-dispctrl #overallgrid [FieldTemplate]="slaReportTemplate" [GridData]="repListData" [GridProp]="GridProp"
                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
              </grid-dispctrl>
            </ng-container>
  
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-center">
              <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    </div>
  </ng-container>
  <!--Vendor SLA Report-->
  <ng-container *ngIf="selectedView == 'vendorslareport'">
    <!-- <nav aria-label="breadcrumb" class="breadcrumbSection">
      <div class="d-flex w-100 justify-content-between">
        <ul class="breadcrumb m-0 flex-fill">
          <li class="breadcrumb-item"><a href="index.html">Report</a></li>
          <li class="breadcrumb-item active" aria-current="page">Master Report</li>
        </ul>     
      </div>
    </nav> -->
    <div class="contentScroll p-2">
      <ng-container *ngIf="(SearchTemplate_VendorSLA != null && SearchTemplate_VendorSLA != undefined && SearchTemplate_VendorSLA.length > 0)">
        <searchby  [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate_VendorSLA" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
          [FormProp]="FormProp" (SearchData)="SearchDataVendorSLAReport($event)" class="customForm">
        </searchby>
      </ng-container>
  
      <div class="contentScroll p-2 simpleCard">
        <div class="card h-100 m-0">
          <div class="card-header"
            *ngIf="(searchEvent.item !=null && searchEvent.item != undefined)">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>
          <div class="card-body overflow-auto">
            <!-- <div class="gridAction">
              <div class="d-flex justify-content-between">
                <label class="d-flex">
                  <span class="pe-2">Show</span>
                  <select class="form-select form-select-sm align-self-center" name="complex-table1_length"
                    aria-controls="complex-table1" [(ngModel)]="curItemsPerPage" (change)="pageItemsChange()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option> -->
                    <!-- <option value=-1>All</option> -->
                  <!-- </select>
                  <span class="ps-2">entries</span>
                </label> -->
<!--   
                <div class="col-auto flex-fill">
                  <actionbar [actionList]="Config.ActionButtons" (butClick)="actButtonClick($event)"
                    [noActionClass]="true">
                  </actionbar>
                </div>
              </div>
            </div> -->
  
           <ng-container *ngIf="(vendorSlaReportTemplate != null && vendorSlaReportTemplate != undefined && vendorSlaReportTemplate.length > 0)">
              <grid-dispctrl #overallgrid [FieldTemplate]="vendorSlaReportTemplate" [GridData]="repListData" [GridProp]="GridProp"
                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
              </grid-dispctrl>
            </ng-container>
  
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-center">
              <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    </div>
  </ng-container>
  <!--Vendor Report-->
  <ng-container *ngIf="selectedView == 'vendorreport'">
   
    <div class="contentScroll p-2">
      <ng-container *ngIf="(SearchTemplate_VendorReport != null && SearchTemplate_VendorReport != undefined && SearchTemplate_VendorReport.length > 0)">
        <searchby  [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate_VendorReport" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
          [FormProp]="FormProp" (SearchData)="SearchDataVendorReport($event)" class="customForm">
        </searchby>
      </ng-container>
  
      <div class="contentScroll p-2 simpleCard">
        <div class="card h-100 m-0">
          <div class="card-header"
            *ngIf="(searchEvent.item !=null && searchEvent.item != undefined)">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>
          <div class="card-body overflow-auto">
            
           <ng-container *ngIf="(vendorReportTemplate != null && vendorReportTemplate != undefined && vendorReportTemplate.length > 0)">
              <grid-dispctrl #overallgrid [FieldTemplate]="vendorReportTemplate" [GridData]="repListData" [GridProp]="GridProp"
                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
              </grid-dispctrl>
            </ng-container>
  
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-center">
              <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    </div>
  </ng-container>

   <!--userwiseaudittrail Report-->
   <ng-container *ngIf="selectedView == 'userwiseaudittrailreport'">
   
    <div class="contentScroll p-2">
      <ng-container *ngIf="(SearchTemplate_UserwiseaudittrailReport != null && SearchTemplate_UserwiseaudittrailReport != undefined && SearchTemplate_UserwiseaudittrailReport.length > 0)">
        <searchby  [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate_UserwiseaudittrailReport" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
          [FormProp]="FormProp" (SearchData)="SearchDataUserAuditReport($event)" class="customForm">
        </searchby>
      </ng-container>
  
      <div class="contentScroll p-2 simpleCard">
        <div class="card h-100 m-0">
          <div class="card-header"
            *ngIf="(searchEvent.item !=null && searchEvent.item != undefined)">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>
          <div class="card-body overflow-auto">
            
           <ng-container *ngIf="(userwiseaudittrailReportTemplate != null && userwiseaudittrailReportTemplate != undefined && userwiseaudittrailReportTemplate.length > 0)">
              <grid-dispctrl #overallgrid [FieldTemplate]="userwiseaudittrailReportTemplate" [GridData]="repListData" [GridProp]="GridProp"
                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
              </grid-dispctrl>
            </ng-container>
  
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-center">
              <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    </div>
  </ng-container>


  
  
  <!-- Loader section -->
  <div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
  </div>
  
  <!-- Filter Pop-up -->
  <div *ngIf="DispFilter">
    <filter *ngIf="(FilterReportTemplate!= undefined && FilterReportTemplate!= null && FilterReportTemplate.length > 0)"
      [FieldTemplate]="FilterReportTemplate" [GridData]="repListDataALL" (close)="showFilter($event)"
      (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
  </div>
  
  
  <!-- Excel Download -->
  <excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

  <!-- Notification section -->
<div *ngIf="displayNotifybox">
  <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
    (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>