<!--Sidebar Filters-->
<div role="dialog"  class="offcanvas offcanvas-end w-50 show" [ngStyle]="{'visibility':'visible'}" tabindex="-1" id="filterContents">

  <div class="offcanvas-header">
    <h2 class="offcanvas-title">{{'Filter' | translate}}</h2>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
      (click)="closeFilterClick()"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row" *ngFor="let rowitem of FilterColsDet; let idx=index">
      <div class="col-lg-6">
        <div class="form-group">
          <!-- FieldName -->
          <label for="field_{{rowitem.displayname.slice(0,4)}}">{{rowitem.displayname | translate }} :</label>

          <!-- Operators -->
          <select id="field_{{rowitem.displayname.slice(0,4)}}_text" class="form-control form-control-sm" *ngIf="rowitem.type == 'text'"
            [(ngModel)]="selectedFilter[idx].oper">
            <option *ngFor="let x of FiltOperator.Text" [ngValue]="x.code">{{x.desc}}</option>
          </select>

          <select id="field_{{rowitem.displayname.slice(0,4)}}_list" class="form-control form-control-sm" *ngIf="rowitem.type == 'list'"
            [(ngModel)]="selectedFilter[idx].oper">
            <option *ngFor="let x of FiltOperator.List" [ngValue]="x.code">{{x.desc}}</option>
          </select>

          <select id="field_{{rowitem.displayname.slice(0,4)}}_multi" class="form-control form-control-sm" *ngIf="rowitem.type == 'multi'"
            [(ngModel)]="selectedFilter[idx].oper">
            <option *ngFor="let x of FiltOperator.Multi" [ngValue]="x.code">{{x.desc}}</option>
          </select>

          <select id="field_{{rowitem.displayname.slice(0,4)}}_number" class="form-control form-control-sm" *ngIf="rowitem.type == 'number'"
            [(ngModel)]="selectedFilter[idx].oper">
            <option *ngFor="let x of FiltOperator.Number" [ngValue]="x.code">{{x.desc}}</option>
          </select>

          <select id="field_{{rowitem.displayname.slice(0,4)}}_date"class="form-control form-control-sm" *ngIf="rowitem.type == 'date'"
            [(ngModel)]="selectedFilter[idx].oper">
            <option *ngFor="let x of FiltOperator.Date" [ngValue]="x.code">{{x.desc}}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-6 align-self-end">
        <!-- Values field -->
        <div class="form-group">
        
          <!-- Text -->
          <input id="field_{{rowitem.displayname.slice(0,4)}}" name="seltext" *ngIf="rowitem.type == 'text'" type="text" class="form-control form-control-sm"
            [(ngModel)]="selectedFilter[idx].value" />

          <!-- List -->
          <div *ngIf="rowitem.type == 'list'">
            <!-- && rowitem.datalist.length > 0"> -->
            <!-- <select *ngIf="rowitem.type == 'list'" kendo-multi-select
                              data-text-field="'text'" data-value-field="'text'"
                              data-source="rowitem.datalist"
                              style="width: 100%; font-weight:normal;" [(ngModel)]="selectedFilter[idx].value" /> -->

            <ng-select [items]="rowitem.datalist" [(ngModel)]="selectedFilter[idx].value" [multiple]="true"
              [bindLabel]="'text'" [bindValue]="'text'" placeholder="Select" appendTo="body" [closeOnSelect]="true">
            </ng-select>
          </div>

          <!-- Multi -->
          <div *ngIf="rowitem.type == 'multi'">
            <!-- && rowitem.datalist.length > 0"> -->
            <!-- <select *ngIf="rowitem.type == 'list'" kendo-multi-select
                              data-text-field="'text'" data-value-field="'text'"
                              data-source="rowitem.datalist"
                              style="width: 100%; font-weight:normal;" [(ngModel)]="selectedFilter[idx].value" /> -->

            <ng-select [items]="rowitem.datalist" [(ngModel)]="selectedFilter[idx].value" [multiple]="true"
              [bindLabel]="'text'" [bindValue]="'text'" placeholder="Select" appendTo="body" [closeOnSelect]="true">
            </ng-select>
          </div>

          <!-- Number -->
          <div *ngIf="rowitem.type == 'number'">
            <div [hidden]="selectedFilter[idx].oper == 'BW'">
              <input type="number" id="field_{{rowitem.displayname.slice(0,4)}}" 
              name="selectedFilter[idx].value" class="form-control form-control-sm"
                [(ngModel)]="selectedFilter[idx].value" />
            </div>

            <div [hidden]="selectedFilter[idx].oper != 'BW'" class="row">
              <div class="col-6">
                <label class="sr-only"  for="selnumfilter2">Number</label>
                <input type="number" id="selnumfilter2" name="selnumfilter2"  class="form-control form-control-sm" [(ngModel)]="selectedFilter[idx].value" />
              </div>
              <div class="col-6">
                <label class="sr-only" for="selnumfilter3">Number</label>
                <input type="number" id="selnumfilter3" name="selnumfilter3"  class="form-control form-control-sm" [(ngModel)]="selectedFilter[idx].value1" />
              </div>
            </div>
          </div>

          <!-- Date -->
          <div *ngIf="rowitem.type == 'date'">
            <div [hidden]="selectedFilter[idx].oper == 'BW'">

              <!-- value="{{ IsDate(selectedFilter[idx].value)? (selectedFilter[idx].value | localizedDate): '' }}" -->
              <!-- <input type="text" class="datepick form-control form-control-sm" style='width:100%;cursor:pointer' bsDatepicker
                [bsConfig]="{containerClass: 'theme-default', showWeekNumbers:false, adaptivePosition: true }"
                placement="top"
                value="{{ (selectedFilter[idx].value == null || selectedFilter[idx].value == undefined || selectedFilter[idx].value.length == 0) ? '':
                 ( selectedFilter[idx].value | localizedDate)}}"
                [(ngModel)]="selectedFilter[idx].value" (ngModelChange)="onDateChange(idx)" /> -->

              <div class="input-group input-group-sm">
                <label class="sr-only" for="datefilter">Date</label>
                <input type="text" id="datefilter" name="datefilter" class="datepick form-control form-control-sm form-control form-control-sm-sm"
                  ngbDatepicker style='cursor:pointer;background-color: white;' [(ngModel)]="selectedFilter[idx].value"
                  readonly="true"
                  [startDate]="((selectedFilter[idx].value == null || selectedFilter[idx].value == undefined || selectedFilter[idx].value.length == 0 ) ? {year:0, month:0, day:0}: 
                      {year: selectedFilter[idx].value.getFullYear(), month: (selectedFilter[idx].value.getMonth() + 1), day: selectedFilter[idx].value.getDate() })"
                  #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="onDateChange(idx)" container="body"
                  placement="bottom auto" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary border-left-0 calendar" type="button"
                    (click)="d1.toggle()"><span class="sr-only">Date picker icon</span><i class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>

            <div [hidden]="selectedFilter[idx].oper != 'BW'" class="row">
              <div class="col-6">
                <!-- <input type="text" class="datepick form-control form-control-sm" style='width:100%;cursor:pointer' bsDatepicker
                  [bsConfig]="{containerClass: 'theme-default', showWeekNumbers:false, adaptivePosition: true }"
                  placement="top"
                  value="{{ (selectedFilter[idx].value == null || selectedFilter[idx].value == undefined || selectedFilter[idx].value.length == 0 ) ? '': ( selectedFilter[idx].value | localizedDate)}}"
                  [(ngModel)]="selectedFilter[idx].value" (ngModelChange)="onDateChange(idx)" /> -->

                <div class="input-group input-group-sm">
                  <label class="sr-only" for="datefilter1">Date</label>
                  <input type="text" id="datefilter1" name="datefilter1" class="datepick form-control form-control-sm form-control form-control-sm-sm"
                    ngbDatepicker style='cursor:pointer;background-color: white;'
                    [(ngModel)]="selectedFilter[idx].value" readonly="true"
                    [startDate]="((selectedFilter[idx].value == null || selectedFilter[idx].value == undefined || selectedFilter[idx].value.length == 0 ) ? {year:0, month:0, day:0}: 
                        {year: selectedFilter[idx].value.getFullYear(), month: (selectedFilter[idx].value.getMonth() + 1), day: selectedFilter[idx].value.getDate() })"
                    #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect)="onDateChange(idx)" container="body"
                    placement="bottom auto" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary border-left-0 calendar" type="button"
                      (click)="d2.toggle()"><span class="sr-only">calendar</span><i class="fa fa-calendar" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!-- <input type="text" class="datepick form-control form-control-sm" style='width:100%;cursor:pointer' bsDatepicker
                  [bsConfig]="{containerClass: 'theme-default', showWeekNumbers:false, adaptivePosition: true }"
                  placement="top"
                  value="{{ (selectedFilter[idx].value1 == null || selectedFilter[idx].value1 == undefined || selectedFilter[idx].value1.length == 0 ) ? '': ( selectedFilter[idx].value1 | localizedDate)}}"
                  [(ngModel)]="selectedFilter[idx].value1" (ngModelChange)="onDateChange(idx)" /> -->

                <div class="input-group input-group-sm">
                  <label class="sr-only" for="datefilter2">Date</label>
                  <input type="text"  id="datefilter2" name="datefilter2" class="datepick form-control form-control-sm form-control form-control-sm-sm"
                    ngbDatepicker style='cursor:pointer;background-color: white;'
                    [(ngModel)]="selectedFilter[idx].value1" readonly="true"
                    [startDate]="((selectedFilter[idx].value1 == null || selectedFilter[idx].value1 == undefined || selectedFilter[idx].value1.length == 0 ) ? {year:0, month:0, day:0}: 
                        {year: selectedFilter[idx].value1.getFullYear(), month: (selectedFilter[idx].value1.getMonth() + 1), day: selectedFilter[idx].value1.getDate() })"
                    #d3="ngbDatepicker" (click)="d3.toggle()" (dateSelect)="onDateChange(idx)" container="body"
                    placement="top auto" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary border-left-0 calendar" type="button"
                      (click)="d3.toggle()"><span class="sr-only">calendar</span><i class="fa fa-calendar" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas-footer">
    <div class="d-flex justify-content-end p-3">
      <button type="button" class="btnCustom btnPrimary rounded-pill" data-bs-dismiss="modal"
        (click)="frameFilterCondn()" value="{{'OK' | translate }}">{{'Apply' | translate }}</button>
      <button type="button" class="btnCustom btnNegative rounded-pill" data-bs-dismiss="modal"
        (click)="clearFilterCondn()" value="{{'Clear' | translate }}">{{'Reset' | translate }}</button>
    </div>
  </div>
</div>

<!-- NOTE: This is required at this position to have the background as modal -->
<div class="offcanvas-backdrop fade show"></div>