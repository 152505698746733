<form #form *ngIf="(FormTemplate!=undefined && FormTemplate!=null)">

    <div class="row" [ngClass]="{'form-inline': (!CaptionTop)}">
      <ng-container *ngFor="let key of FormTemplate;let key_indx= index" >
        <!-- <div class="col-{{ColSize}}"  -->
        <div class="col-xs-{{respColSize.xs}} col-sm-{{respColSize.sm}} col-md-{{respColSize.md}} col-lg-{{respColSize.lg}} col-xl-{{respColSize.xl}}"
          [hidden]="!showFormCol(key.FieldName)"
          [ngClass]="{'non-editable': key.Editable == 0, 'align-self-end': (key.Control.Type == 'button')}">
          <div class="form-group">
            <!-- <label for="field{{key.DisplayName.split(' ').join('')}}" *ngIf="(key.Control.Type != 'button' && key.Control.Type != 'icon' && key.Control.Type != 'link')" [ngClass]="[key.EditValidate.Required ? 'required':'']">
              {{key.DisplayName | translate}}
            </label> -->

            <label for="{{formRandomId}}" *ngIf="(key.Control.Type != 'button' && key.Control.Type != 'link')"
                [ngClass]="[key.EditValidate.Required ? 'required':'']"
                [attr.aria-required]="(key.EditValidate.Required ? 'true': 'false')">
              {{key.DisplayName | translate}}
              <span class="sr-only asterik" aria-label="Asterik-Required" aria-hidden="true"
              *ngIf="(key.EditValidate.Required)">*</span>
            </label>

            <!-- <div [ngClass]="{'readonly': getReadOnly(key, FormData)}"> -->
              <field-control [parentRandomId]="formRandomId" #fieldctrl [item_indx]="0" [key_indx]="key_indx" [item]="FormData" [key]="key" [initedit]="IsEditable"
                [cellLock]="cellLock" [parent]="this.parent" [parentNode]="this" (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)" 
                [attr.data-fieldname]="key.FieldName" [attr.data-controltype]="key.Control.Type"
                [showcontrolalways]="ShowControlAlways"></field-control>
            <!-- </div> -->
          </div>
        </div>
      </ng-container>

    </div>

    <!-- <div class="form-group row nomarginLR col-{{ColSize}}" *ngFor="let key of FormTemplate;let key_indx= index"
      [ngStyle]="{ 'overflow': 'hidden'}" [hidden]="!showFormCol(key.FieldName)" [ngClass]="{'non-editable': key.IsEditable == 0}">
      <div class="col-{{CaptionColSize}}">
        <span *ngIf="(key.Control.Type != 'button' && key.Control.Type != 'icon' && key.Control.Type != 'link')"
          [ngClass]="{'capbold': CaptionBold}">
          {{key.DisplayName | translate}}
          <sup *ngIf="key.EditValidate.Required" style="color: red">*</sup>
          :
        </span>
      </div>
      <div class="col-{{(DataColSize)}}">
        <field-control [parentRandomId]="formRandomId" [item_indx]="0" [key_indx]="key_indx" [item]="FormData" [key]="key" [initedit]="IsEditable"
          [parentNode]="this" (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"></field-control>
      </div>
    </div> -->
  
</form>
