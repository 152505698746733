import { Component, EventEmitter, ElementRef, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionCode, BGVStatus, ComponentCodes, ComponentConfig, ProcessStatus, RoleCodes, StatusColorCode } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { GridComponent } from 'src/modules/grid/grid.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate,FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { RequestService } from '../request.service';
import { ModalService } from 'src/modules/modal/modal.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'quality-control',
  templateUrl: './quality-control.component.html'
})

@AutoUnsubscribe
export class QualityControlComponent implements OnInit {

  @Input() RequestId: number = 0;
  @Input() SelectedTabName: string = '';
  @Input() RequestData: any;

  @Output() RefreshRequest = new EventEmitter<any>();

  @ViewChildren('qcmaingrid') qcmaingrid!: QueryList<GridDisplayControlComponent>;
  @ViewChild('additionalsectiongrid', { static: false }) additionalsectiongrid!: GridComponent;
  // @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;

  public QCTemplate: FieldTemplate[] = [];
  public QCPermission: any = [];
  public QCProperties: any = [];
  public QCData: any[] = [];
  public QCAdditionalSectionData: any[] = [];

  public IsLoading: boolean = false;
  public IsReadonly: boolean = false;
  public openQCSection: boolean = false;

  public sectionName: string = '';
  public sectionDisplayName: string = '';
  public sectionData: any[] = [];
  public DisplayAttachment: boolean = false;

  public subscriptionList$: Subscription[] = [];

  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();

  public curItemsPerPage: number = 10;
  public flag : string ="fromupdateoverallstatus";
  public ReinitiateCaseTemplate: any[] = [];
  public viewReinitiateCase: boolean = false;
  public ReasonForReinitiateCase:string='';

  public isAdditonalSectionClicked: boolean = false;
  public isShowOverallCaseButtonClicked: boolean = false;



  public GridProp: any = {
    Editable: true,
    IsSortable: false
  };

  public AttachPerm: any = {
    Upload: true,
    Download: false
  };
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };
  public FormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': false,
    'IsEditable': true,
    // 'ShowControlAlways': true
  };
  public ReinitiateCaseData: any = {
    IsEditable: 1,
    Code: 0,
    CodeDescription: null
  };

  public GlobalConfig: any = {};
  public focusableEles!: HTMLElement[];
  public firstFocusableEle!: HTMLElement;
  public lastFocusableEle!: HTMLElement;
  constructor(private modalService: ModalService,private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
    , private _appSharedService: AppSharedService) {
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      // if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
      //   this.GridProp.Editable = false;
      //   this.IsReadonly = true;
      // }
      this.getQCTemplate();
    }

  }

  setDefaultPageFocus() {

    setTimeout(() => {

      this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
      input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
           button:not([disabled]), [tabindex="0"]`);
      this.focusableEles = Array.prototype.slice.call(this.focusableEles);

      if (this.focusableEles.length > 0) {
        this.firstFocusableEle = this.focusableEles[0];
        this.lastFocusableEle = this.focusableEles[this.focusableEles.length - 1];

        this.firstFocusableEle.focus();
      }


    }, 50);
  }
  getQCTemplate(): void {

    this.IsLoading = true;
    let odata: ComponentConfig = {
      Component: 'QualityControl', ComponentCode: ComponentCodes.QualityControl
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        this.QCTemplate = res.Data.FieldTemplate!;
        this.QCPermission = res.Data.Permission;
        this.setCompProperties(res.Data.Properties!);
        this.ReinitiateCaseTemplate = this.QCTemplate.filter(x => x.Group === 'ReinitiateCaseQC');


        // this.QCPermission = JSON.parse(`["Submit","CaseClose","InterimReport","FinalReport","SupplementaryReport"]`);
        this.getCheckDocumentDetailsList(this.flag);
        this.setDefaultPageFocus();

        this.QCProperties.UploadFileType = JSON.parse(`{
          "UploadFileType": "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/jpeg,image/png,application/pdf"
        }`);
        //Action Buttons
        // this.QCProperties.ActionButtons = JSON.parse(`[{"ActionCode":"AC005","Name":"SubmittoVendor","Icon":"","CustomClass":"rounded-pill btnCustom btnPrimary","DisplayName":"Submit to vendor","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Group":"QCStatus","Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":4,"ShowArrow":true},{"ActionCode":"AC012","Name":"Submittonongreen","Icon":"","CustomClass":"rounded-pill btnCustom btnPrimary","DisplayName":"Submit to Non-green","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Group":"QCStatus","Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":3,"ShowArrow":true},{"Name":"Cancel","Icon":"","CustomClass":"rounded-pill btnCustom btnNegative","DisplayName":"Closed","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Group":"QCStatus","Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1,"ShowArrow":false}]`);
      }
      // this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getUpdateOverallStatusTemplate.');
      });
    this.subscriptionList$.push(subscribe$);
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {

      // 2) Blocking Cols based on custom values
      this.QCProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.QCProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  onClick(event: any): void {
    let selitem: any = event.item;

    if (event.fieldname.toUpperCase() === 'ACTION') {

      // event.icon.IconCSS
      switch (event.icon.Value) {
        case '0':
          //Upload

          if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0
            && !isNullOrUndefined(this.sectionData[0].FileName) && this.sectionData[0].FileName !== '') {
            this.deleteAttachment(true);
          } else {
            selitem.IsModified = 1;
            selitem.IsRowHighlight = selitem.IsModified;
            this.DisplayAttachment = true;
          }
          break;
      }
    }

    console.log(selitem);
    if (event.fieldname.toUpperCase() === 'FILEACTION') {

      // event.icon.IconCSS
      switch (event.icon.Value) {
        case '0':
          //Download
          this.downloadAttachment(selitem);

          break;
      }
    }

  }

  downloadAttachment(data: any) {
    this.IsLoading = true;

    let odata: any = {
      'Module': data.FileModule,
      'Reference1': Number(this.RequestId),
      'Reference2': data.CheckId,
      'Reference3': 0,
      'Reference4': 0,
      'TransactionId': data.FileReferenceId,
      // 'FilePath': data.FilePath,
      'FilePath': '',
      'FileName': data.FileName
    };

    let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
      // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
      //   console.log(result);
      // }
      if (!isNullOrUndefined(result)) {
        let url = window.URL.createObjectURL(result.data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        // a.download = res.filename;
        a.download = data.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        // this._toastr.success('Resume downloaded successfully', this.CompTitle);
        console.log('Resume downloaded successfully');

        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Document downloaded successfully',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;
      }
      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  onChange(event: any, qcSectionName?: any): void {
    let selitem: any = event.item;
    let itemindex: any = event.item_index;

    // Emitting onchange event of resource plan to parent container
    selitem.IsModified = 1;
    selitem.IsRowHighlight = selitem.IsModified;

    if(!isNullOrUndefined(selitem.ReasonForReinitiateCase))
    {
      this.ReasonForReinitiateCase=selitem.ReasonForReinitiateCase;
    }

    switch (event.fieldname.toUpperCase()) {
      case 'QCSTATUSCODE':
        if ((isNullOrUndefined(selitem.QCStatusCode) || selitem.QCStatusCode === '') && !isNullOrUndefined(selitem.OldQCStatusCode) && selitem.OldQCStatusCode !== '') {

          // this.showNotificationMessage('Please select atleast one QC status.', false);
          setTimeout(() => {
            selitem.QCStatusCode = selitem.OldQCStatusCode
            selitem.QCStatusDesc = selitem.OldQCStatusDesc
            selitem.ReportStatus = selitem.OldReportStatus
            selitem.ReportStatusDesc = selitem.OldReportStatusDesc
            selitem.AmendmentStatus = selitem.OldAmendmentStatus
            selitem.AmendmentStatusDesc = selitem.OldAmendmentStatusDesc
            selitem.QCComment = selitem.OldQCComment

            selitem.IsModified = 0;
            selitem.IsRowHighlight = selitem.IsModified;
          }, 50);

        } else {
          selitem.ActionCode = selitem.QCStatusCode
        }

        this.changeCellLockFlag(selitem, itemindex, qcSectionName);

        // // Enable/Disable AmendmentStatus based on QCStatus
        // let colmlist: string = 'AmendmentStatus';
        // let disableCtrls: boolean = (selitem.QCStatusCode == 'AC004' ? true : false);
        // this.enableDisableGridFields(colmlist, itemindex, disableCtrls, qcSectionName);

        break;
    }

    this.enableDisableButtons();

    let eventdata = {
      fieldname: event.fieldname
    };
  }

  setCellLock(fieldname: string, item: any, parent: QualityControlComponent): boolean {
    let bLock = false;
    let BlkCols: any[] = [];

    if (!isNullOrUndefined(parent.QCProperties.BlockCols) && parent.QCProperties.BlockCols.length > 0) {
      parent.QCProperties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value === item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (!isNullOrUndefined(parent.QCProperties.BlkReportStatus) && parent.QCProperties.BlkReportStatus.length > 0) {
      parent.QCProperties.BlkReportStatus.forEach((blockitem: any) => {
        if (!isNullOrUndefined(blockitem.Value) && blockitem.Value.filter((x: any) => x === item[blockitem.Field]).length === 0) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
        // if (blockitem.Value !== item[blockitem.Field]) {
        //   blockitem.BlockCols.forEach((item: any) => {
        //     BlkCols.push(item);
        //   });
        // }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }

    return bLock;
  }

  changeCellLockFlag(selitem: any, itemindex: any, qcSectionName?: any) {
    let griditem = this.qcmaingrid.filter(x =>
      x.GridId === 'QC_' + qcSectionName + '_');
    if (griditem.length > 0 && !isNullOrUndefined(griditem[0])) {
      // setTimeout(() => {

      let temp: any = [];
      temp.push(selitem);
      griditem[0].initCellLock(temp);
      griditem[0].refreshAllFieldsForRow(selitem, itemindex);

      // }, 50);

    }
  }

  // enableDisableGridFields(colmlist: string, itemindex: any, flag: boolean, qcSectionName?: any) {
  //   let griditem = this.qcmaingrid.filter(x =>
  //     x.GridId === 'QC_' + qcSectionName + '_');
  //   if (griditem.length > 0 && !isNullOrUndefined(griditem[0])) {
  //     griditem[0].enableFieldsForRow(colmlist, itemindex, flag);
  //   }
  // }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'SUBMIT':
        if (event.result.toUpperCase() === 'YES') {
        }
        break;
      case 'STOPCASESUBMIT':
        if (event.result.toUpperCase() === 'YES') {
          this.requestStopCase();
        }
        break;

      case 'STOPCASECANCEL':
        if (event.result.toUpperCase() === 'YES') {
          if (!isNullOrUndefined(this.sectionData[0].FileName) && this.sectionData[0].FileName !== '') {
            this.deleteAttachment(false);
          }
          else {
            this.sectionData = [];
            this.openQCSection = false;
          }
          // else if (!isNullOrUndefined(this.reportData[0].Comments) && this.reportData[0].Comments !== '') {
          //     this.openReport = false;
          // }

        }
        break;
      case 'REINITIATECASE':
        if (event.result.toUpperCase() === 'YES') {
            this.viewReinitiateCase=true;
            this.openModal('ReinitiateCaseQC')
        }
        break;
    
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  actionButtonClick(data: any) {

    if (!isNullOrUndefined(data.ActionCode) && data.ActionCode !== '') {
      switch (data.ActionCode.toUpperCase()) {
        //Submit Case
        case 'AC002':
          let modifieditems = this.QCData.filter(x => x.IsModified === 1 && !isNullOrUndefined(x.QCStatusCode) && x.QCStatusCode !== '');

          if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0 && this.validateMandatoryFields(modifieditems)) {
            this.SubmitQC(false, modifieditems);
          }
          break;

        //Close Case                
        case 'AC014':

          if (this.QCData.length > 0) {
            this.QCData.forEach((item: any) => {
              item.ActionCode = 'AC014';
             // item.IsModified = true;
              item.IsClosed = true;
            });

            if (this.validateMandatoryFields(this.QCData)) {
              this.SubmitQC(true, this.QCData);
            }
          }
          // else {
          //   this.submitCaseClose();
          // }

          break;

        //StopCase
        case 'AC017':
          // Stop Case Comments Section Click
          if (!isNullOrUndefined(data.Name) && data.Name !== '' && data.Name.toUpperCase() === 'STOPCASESUBMIT') {
            // if (this.validateAdditionalSectionMandatoryFields(this.sectionData)) {
            this.showNotificationMessage('Are you sure you want to stop the case?', 1, 'STOPCASESUBMIT', this.sectionData, 'Yes', 'No');
            // }
          }

          // Stop Case Button Click 
          if (!isNullOrUndefined(data.Name) && data.Name !== '' && data.Name.toUpperCase() === 'STOPCASE') {
            this.openAdditionalSection(data);
          }

          break;

      }
    }

    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        //StopCase
        case 'STOPCASECANCEL':
          // this.showStopCaseConfirmation();
          this.closeAdditionalSection();
          break;

        case 'REINITIATECASE':
          this.showReinitiateCaseConfirmation();
            break;

      }
    }

  }

  getCheckDocumentDetailsList(flag:string) {
    // this.IsLoading = true;

    let odata: object = { 'requestId': this.RequestId, 'checkId': 0, 'flag':flag };

    this.QCData = [];

    let subscribe$: Subscription = this._requestService.getCheckDocumentDetailsList(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.QCData = result.Data;

        this.QCData.forEach(item => {
          if (item.IsEditable === 1) {
            item.QCStatusCode = null;
            item.QCStatusDesc = null;

            item.AmendmentStatus = null;
            item.AmendmentStatusDesc = null;

            item.ReportStatus = null;
            item.ReportStatusDesc = null;
          }
        });

        this.setActionButtonPermissions();
        this.enableDisableButtons();
        this.getQCadditionalSectionList();
      }
      // this.IsLoading = false;
    },
      err => {
        console.log('Error on getting getCheckDocumentDetailsList.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  getQCadditionalSectionList() {
    // this.IsLoading = true;

    let odata: object = { 'requestId': this.RequestId, 'checkId': null };

    let subscribe$: Subscription = this._requestService.getActivityLogList(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.QCAdditionalSectionData = result.Data;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting getQCadditionalSectionList.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.QCProperties.ActionButtons) && this.QCProperties.ActionButtons.length > 0) {

      this.QCProperties.ActionButtons.forEach((item: any) => {

        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          let peritem = this.QCPermission.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
          }
          switch (item.Name.toUpperCase()) {
            case 'SUBMIT':
              // item.IsApplicable = this.enableSubmitCase();
              item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
              break;

            case 'CASECLOSE':
              // item.IsApplicable = this.enableCloseCase();
              item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
              break;

            case 'INTERIMREPORT':
              item.IsApplicable = this.enableInterimReport();
              // item.IsApplicable = true;
              break;

            case 'FINALREPORT':
              item.IsApplicable = this.enableFinalReport();
              // item.IsApplicable = true;
              break;

            case 'SUPPLEMENTARYREPORT':
              item.IsApplicable = this.enableSupplementaryReport();
              // item.IsApplicable = true;
              break;

            case 'STOPCASE':
            case 'STOPCASESUBMIT':
            case 'STOPCASECANCEL':
              // item.IsApplicable = this.enableStopCase();
              item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
              break;

            case 'REINITIATECASE':
              item.IsApplicable = this.RequestData.IsStopCaseSubmitted===true  && this.RequestData.IsStopCase===true ? true : false;
              break;
    
          }
        }
      });
    }
  }

  enableSubmitCase(): boolean {
    let flag: boolean = false;
    if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0
      && !isNullOrUndefined(this.QCData.filter(x => x.IsEditable === 1))
      && this.QCData.filter(x => x.IsEditable === 1).length > 0
      && this.RequestData.EnableClosure === false
      && this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsClosed === false
      && !this.enableCloseCase()
    ) {
      flag = true;
    }
    return flag;
  }

  enableInterimReport(): boolean {
    let flag: boolean = false;
    if (
      this.RequestData.EnableInterimDownload === 1 && this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsStopCase === false
    ) {
      flag = true;
    }
    return flag;
  }

  enableSupplementaryReport(): boolean {
    let flag: boolean = false;
    if (
      this.RequestData.EnableSupplimentaryDownload === 1 && this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsStopCase === false
    ) {
      flag = true;
    }
    return flag;
  }

  enableFinalReport(): boolean {
    let flag: boolean = false;
    if (
      this.RequestData.EnableFinalDownload === 1 && this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsStopCase === false
    ) {
      flag = true;
    }
    return flag;
  }

  // enableCloseCase(): boolean {
  //   let flag: boolean = false;
  //   if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0
  //     && this.QCData.filter(x => x.IsEditable === 1).length > 0
  //     && this.QCData.filter(x => (x.QCStatusCode === '' || isNullOrUndefined(x.QCStatusCode))).length === 0
  //     && this.QCData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination)).length === 0
  //     && this.QCData.filter(x => (x.UploadStatus !== BGVStatus.Final_Green_FR_submitted && x.UploadStatus !== BGVStatus.Green_SR_Submitted && x.UploadStatus !== BGVStatus.Closed__Green)).length === 0
  //     && this.QCData.filter(x => !isNullOrUndefined(x.PendingWith) && x.PendingWith !== RoleCodes.QC.toString()).length === 0
  //   ) {
  //     if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0
  //       && this.QCData.filter(x => (x.QCStatusCode === '' || isNullOrUndefined(x.QCStatusCode))).length === 0
  //       && this.QCData.filter(x => x.QCStatusCode !== ActionCode.QC_Accept && (x.UploadStatus === BGVStatus.Final_Green_FR_submitted || x.UploadStatus === BGVStatus.Green_SR_Submitted || x.UploadStatus === BGVStatus.Closed__Green)).length === 0
  //     ) {
  //       flag = true;

  //     }
  //   }
  //   return flag;
  // }

  enableCloseCase(): boolean {
    let flag: boolean = false;
    if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0
      && this.QCData.filter(x => x.ColorCode === StatusColorCode.Green || x.ColorCode === '').length === this.QCData.length
      && this.QCData.filter(x => x.QCStatusCode === ActionCode.QC_Accept ).length === this.QCData.length
      && this.QCData.filter(x => (x.UploadStatus === BGVStatus.Final_Green_FR_submitted && x.PendingWith===RoleCodes.QC.toString()
        || x.UploadStatus === BGVStatus.Green_SR_Submitted 
        || x.UploadStatus === BGVStatus.Final_Green_FR_Accepted
        || x.UploadStatus === BGVStatus.Converted_Green_SR_Submitted
        || x.UploadStatus === BGVStatus.Closed__Green)).length === this.QCData.length
       

    ) {
      flag = true;
    }
    return flag;
  }

  enableStopCase(): boolean {
    let flag: boolean = false;
    if (
      this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsClosed === false
    ) {
      flag = true;
    }
    return flag;
  }


  showReinitiateCaseConfirmation() {

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: 'Are you sure you want to Reinitiate the case?',
        yes: 'Yes',
        no: 'No',

        IsHeaderHidden: true,
        HeaderIcon: 'fa fa-check-circle text-success',
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: 'REINITIATECASE',
      item: null
    };

    this.displayNotifybox = true;
  }

  reinitiateCase() {
    this.IsLoading = true;
    this.RequestData.ReasonForReinitiateCase=this.ReasonForReinitiateCase;
    let odata=this.RequestData;

        let subscribe$: Subscription = this._requestService.ReinitiateCase(odata).subscribe(result => {
          if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

              this.notify = {
                info: {
                  header: 'Confirmation Alert',
                  message: 'Case Reinitiated successfully',
                  yes: '',
                  no: '',

                  IsHeaderHidden: true,
                  HeaderIcon: 'fa fa-check-circle text-success',
                  IsCrossEnable: true,
                  SizeClass: 'modal-sm'
                },

                action: '',
                item: null
              };

              this.displayNotifybox = true;
              this.closeModal('ReinitiateCaseQC');
            
          }

          this.IsLoading=false;
          this.refreshRequest();

        },

          err => {
            this.IsLoading = false;
          });
        this.subscriptionList$.push(subscribe$);  
  }

  openModal(id: string) {
    this.modalService.open(id);
    document.getElementById('txtvalue')?.focus();
  }

  closeModal(id: string) 
  {
   this.modalService.close(id); 
  }



  SubmitQC(closeCaseFlag: boolean, modifieditems: any) {
    // let modifieditems = this.QCData.filter(x => x.IsModified === 1 && !isNullOrUndefined(x.QCStatusCode) && x.QCStatusCode !== '');

    // if (this.validateMandatoryFields(modifieditems)) {
    this.IsLoading = true;
    let subscribe$: Subscription = this._requestService.SubmitQC(modifieditems).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (closeCaseFlag === false) {
          this.showNotificationMessage('Submitted successfully', 1, '', null, '', '');
          this.refreshRequest();
        }

        if (closeCaseFlag === true) {
          this.showNotificationMessage('Case closed successfully', 1, '', null, '', '');
          this.getCheckDocumentDetailsList(this.flag);
        }
        // this.getCheckDocumentDetailsList();



      }
      // this.IsLoading = false;
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    // }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  refreshRequest(): void {
    this.RefreshRequest.emit({ 'requestId': this.RequestId });
  }

  validateMandatoryFields(modifieditems: any[]): boolean {
    let valid: boolean = true;


    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
      this.showNotificationMessage('No rows are modified.', 2, '', null, '', '');
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {

      //Report Status
      let valReportStatus = modifieditems.filter(x => (x.UploadStatus === BGVStatus.Interim_NonGreen_IR_submitted_1 || x.UploadStatus === BGVStatus.Final_NonGreen_FR_submitted || x.UploadStatus === BGVStatus.Verified_non_Green_2) && ((isNullOrUndefined(x.ReportStatus)) || x.ReportStatus === ''));

      if (!isNullOrUndefined(valReportStatus) && valReportStatus.length > 0) {
        this.showNotificationMessage('Please provide report status for modified rows.', 2, '', null, '', '');
        valid = false;
      }

      //Amendment Status
      let valAmendmentStatus = modifieditems.filter(x => x.ActionCode === 'AC005' && ((isNullOrUndefined(x.AmendmentStatus)) || x.AmendmentStatus === ''));

      if (!isNullOrUndefined(valAmendmentStatus) && valAmendmentStatus.length > 0) {
        this.showNotificationMessage('Please provide amendment status for modified rows.', 2, '', null, '', '');
        valid = false;
      }

      //Comments Check
      let emptyComments = modifieditems.filter(x => ((isNullOrUndefined(x.QCComment)) || x.QCComment === '')&&x.IsModified===true && x.VendorDocumentStatus!='Not Applicable');
      let msg: string = '';

      if (!isNullOrUndefined(emptyComments) && emptyComments.length > 0) {
        this.showNotificationMessage('Please provide comments for modified rows.', 2, '', null, '', '');
        valid = false;
      }
    }

    return valid;
  }

  // submitCaseClose() {
  //   this.IsLoading = true;

  //   let subscribe$: Subscription = this._requestService.submitCaseClose(this.RequestId).subscribe(result => {
  //     if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

  //       this.showNotificationMessage('Case closed successfully', true);
  //       // this.displayNotifybox = true;
  //       this.refreshRequest();

  //     }
  //     this.IsLoading = false;
  //   },

  //     err => {
  //       this.IsLoading = false;
  //     });
  //   this.subscriptionList$.push(subscribe$);
  // }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }


    // reload grid data
    // if (isNullOrUndefined(this.searchEvent.item.Value) || this.searchEvent.item.Value.length == 0) {
    //   this.SearchData(this.searchEvent, true, false);
    // }
    // else {
    //   this.SearchData(this.searchEvent, false, true);
    // }


  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'FINALREPORT':
        this.downloadReports(event.actionbut, Number(this.RequestData.EnableFinalTransactionId), this.RequestData.FinalReportFileName);
        break;
      case 'SUPPLEMENTARYREPORT':
        this.downloadReports(event.actionbut, Number(this.RequestData.EnableSupplimentaryTransactionId), this.RequestData.SupplimentaryReportFileName);
        break;
      case 'INTERIMREPORT':
        this.downloadReports(event.actionbut, Number(this.RequestData.EnableInterimTransactionId), this.RequestData.InterimReportFileName);
        break;

    }
  }

  downloadReports(data: any, transId: number, fileName: string) {
    this.IsLoading = true;

    let odata: object = {
      'Module': data.ReportType,
      'Reference1': Number(this.RequestId),
      'Reference2': 0,
      'Reference3': 0,
      'Reference4': 0,
      'TransactionId': Number(transId),
      // 'FilePath': data.FilePath,
      'FilePath': '',
      'FileName': fileName
    };

    let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
      // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
      //   console.log(result);
      // }
      if (!isNullOrUndefined(result)) {
        let url = window.URL.createObjectURL(result.data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        // a.download = res.filename;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        // this._toastr.success('Resume downloaded successfully', this.CompTitle);

        this.showNotificationMessage('Document downloaded successfully', 1, '', null, '', '');
      }
      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  showStopCaseConfirmation() {

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: 'Are you sure you want to stop the case?',
        yes: 'Yes',
        no: 'Cancel',

        IsHeaderHidden: true,
        HeaderIcon: 'fa fa-check-circle text-success',
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: 'STOPCASE',
      item: null
    };

    this.displayNotifybox = true;
  }

  requestStopCase() {

    this.IsLoading = true;

    // let odata: any = {
    //   RequestId: this.RequestId,
    //   RequestStatus: this.RequestData.RequestStatus
    // };

    this.sectionData.forEach(item => {
      item.FilePath = ''
    });

    let subscribe$: Subscription = this._requestService.requestStopCaseByQC(this.sectionData[0]).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Request stopped successfully',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;

        this.refreshRequest();

        // this._toastr.success('Request stopped successfully', this.CompTitle);
      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        // this._toastr.warning(result.Message, this.CompTitle);
        console.log(result.Message)

      }
      // this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  enableDisableButtons(): void {
    if (!isNullOrUndefined(this.QCProperties.ActionButtons)
      && this.QCProperties.ActionButtons.length > 0
      && (this.QCProperties.ActionButtons.filter((x: any) => x.Group === 'QCStatus')).length > 0) {

      let modifieditems = this.QCData.filter(x => x.IsModified === 1);

      let primaryPageButt = this.QCProperties.ActionButtons.filter((x: any) =>
        x.Group === 'QCStatus');

      if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0) {
        primaryPageButt.forEach((item: any) => {

          if (!isNullOrUndefined(item.Name) && item.Name !== '') {
            item.IsEnabled = false;
            switch (item.Name.toUpperCase()) {
              case 'SUBMIT':
                if (this.enableSubmitCase()) {
                  item.IsEnabled = true;
                }
                break;
              case 'CASECLOSE':
                // if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0
                //   && this.QCData.filter(x => x.QCStatusCode !== 'AC004'
                //     && (x.UploadStatus !== BGVStatus.Verified_Green && x.UploadStatus !== BGVStatus.Converted_Green)).length === 0
                // ) {
                //   item.IsEnabled = true;

                // }
                if (this.enableCloseCase()) {
                  item.IsEnabled = true;
                }
                break;
              case 'STOPCASE':
                if (!isNullOrUndefined(this.QCData) && this.QCData.length > 0 && this.RequestData.IsStopCaseSubmitted === false
                  && this.QCData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                    || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                    // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                    // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                    )).length === 0
                  && this.RequestData.EnableClosure === false
                  && this.RequestData.IsClosed === false) {
                  item.IsEnabled = true;
                }
                break;
              
              case 'REINITIATECASE':
                item.IsEnabled=true;
            }
          }
        });
      }
    }
  }

  openAdditionalSection(data: any): void {

    this.sectionData = [];

    let secData: any = {
      RequestId: this.RequestId,
      RequestStatus: this.RequestData.RequestStatus,
      ActionCode: data.ActionCode,
      FileReferenceId: 0,
      StopCaseComments: '',
      Report: data.DisplayName,
      Action: '0',
      FileName: '',
      IsEditable: 1,
      IsModified: 0
    };

    this.sectionData.push(secData);

    // Display Name and Filter name for buttons and bread scrum name display
    this.sectionName = data.Name;
    this.sectionDisplayName = data.DisplayName;


    this.openQCSection = true;
  }

  closeAdditionalSection(): void {

    if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0 && (this.sectionData.filter(x => x.IsModified === 1)).length > 0) {
      this.showNotificationMessage('Are you sure you want to cancel the submission?', 2, 'STOPCASECANCEL', null, 'Yes', 'No');


    } else {
      this.openQCSection = false;
    }

  }

  deleteAttachment(reUpload: boolean) {

    if (this.sectionData[0].FileName !== '' && !isNullOrUndefined(this.sectionData[0].FileName)) {

      this.IsLoading = true;

      let odata: object = {
        'Module': 'StopCase',
        'Reference1': Number(this.RequestId),
        'Reference2': 0,
        'Reference3': 0,
        'Reference4': 0,
        'TransactionId': this.sectionData[0].FileReferenceId,
        // 'FilePath': this.sectionData[0].FilePath,
        'FilePath': '',
        'FileName': this.sectionData[0].FileName,
        'UserId': this._appSharedService.UserAccessInfo.actinguserid
      };

      let deleteData: any[] = [];
      deleteData.push(odata);

      let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        }
        this.IsLoading = false;

        if (reUpload === false) {
          this.openQCSection = false;
          this.refreshRequest();
        } else {
          this.sectionData[0].FileReferenceId = 0;
          this.sectionData[0].FileName = '';
          this.sectionData[0].Comments = '';
          this.DisplayAttachment = true;
        }
      },

        err => {
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }
  }

  validateAdditionalSectionMandatoryFields(modifieditems: any[]): boolean {
    let valid: boolean = true;

    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
      this.showNotificationMessage('No rows are modified.', 2, '', null, '', '');
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
      if (isNullOrUndefined(this.sectionData[0].FileName) || this.sectionData[0].FileName === '') {
        this.showNotificationMessage('Please upload the file', 2, '', null, '', '');
        valid = false;
      }
      else if (isNullOrUndefined(this.sectionData[0].StopCaseComments) || this.sectionData[0].StopCaseComments === '') {
        this.showNotificationMessage('Please enter comments', 2, '', null, '', '');
        valid = false;
      }
    }

    return valid;
  }

  closeAttachment(event: any): void {
    console.log(event.data);

    if (!isNullOrUndefined(event) && event.Reload === true) {
      // this.reportData.FilePath = event.data[0].FilePath;
      // this.reportData.TransactionId = event.data[0].TransactionId;
      // this.reportData.FileName = event.data[0].FileName;

      this.sectionData[0].FilePath = event.data[0].FilePath;
      this.sectionData[0].FileReferenceId = event.data[0].TransactionId;
      this.sectionData[0].FileName = event.data[0].FileName;
    } else {
      this.sectionData[0].IsModified = 0;
      this.sectionData[0].IsRowHighlight = this.sectionData[0].IsModified;
    }

    this.DisplayAttachment = false;
  }

  showOverallStatus()
    {
        if(this.isShowOverallCaseButtonClicked)
        {
            this.isAdditonalSectionClicked=false;
            this.isShowOverallCaseButtonClicked=false;
        }
        
        else{
            this.isAdditonalSectionClicked=true;
            this.isShowOverallCaseButtonClicked=true;
        }
    }

}
