import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './core/auth/auth-guard.service';
import { LoginComponent } from './core/login/login.component';
import { RegistryComponent } from './core/registry/registry.component';
import { SwitchUserComponent } from './core/switchuser/switchuser.component';
import { HolidayComponent } from './holiday/holiday.component';
// import { DashBoardComponent } from './home/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { InitiateCaseComponent } from './initiate-case/initiate-case.component';
import { ReportComponent } from './report/report.component';
import { RequestComponent } from './request/request.component';
import { AppMainComponent } from './app-main.component';
import { CheckConfigComponent } from './check-config/check-config.component';
import { CallbackComponent } from './core/callback/callback.component';
import { environment } from 'src/environments/environment';
import { ForgotPasswordComponent } from './core/login/forgot-password.component';

import { VendorComponent } from './admin/vendor-manage/manage-vendor/manage-vendor.component';
import { ManageVolumeComponent } from './admin/vendor-manage/manage-volume/manage-volume.component';
import { AdminComponent } from './admin/admin.component';
import { UserRoleManageComponent } from './admin/user-role-manage/manage-user-role.component';
import { AccChkDocManageComponent } from './admin/acc-chk-doc-manage/acc-chk-doc-manage.component';
import { UploadBUHRComponent } from './admin/upload-buhr/upload-buhr-list.component';
import { CrNumberComponent } from './admin/cr-number/cr-number.component';


const internalRoutes: Routes = [

  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'app', component: AppMainComponent,
    children: [
      { path: 'home', redirectTo: 'home/search', pathMatch: 'full' },
      { path: 'home/dashboard', component: HomeComponent, data: { selectTab: 'dashboard' }, canActivate: [AuthGuardService] },
      { path: 'home/search', component: HomeComponent, data: { selectTab: 'search' }, canActivate: [AuthGuardService] },
      { path: 'home/closedgreen', component: HomeComponent, data: { selectTab: 'closedgreen' }, canActivate: [AuthGuardService] },
      { path: 'home/search/case/:caseid', component: RequestComponent, canActivate: [AuthGuardService] },
      { path: 'reports', redirectTo: 'reports/masterreport', pathMatch: 'full' },
      { path: 'reports/masterreport', component: ReportComponent, data: { selectTab: 'masterreport' }, canActivate: [AuthGuardService] },
      { path: 'reports/slareport', component: ReportComponent, data: { selectTab: 'slareport' }, canActivate: [AuthGuardService] },
      { path: 'reports/vendorslareport', component: ReportComponent, data: { selectTab: 'vendorslareport' }, canActivate: [AuthGuardService] },
      { path: 'reports/vendorreport', component: ReportComponent, data: { selectTab: 'vendorreport' }, canActivate: [AuthGuardService] },
      { path: 'reports/userwiseaudittrailreport', component: ReportComponent, data: { selectTab: 'userwiseaudittrailreport' }, canActivate: [AuthGuardService] },
      {
        path: 'admin', component: AdminComponent, canActivate: [AuthGuardService],
        children: [
          { path: '', redirectTo: 'vendor', pathMatch: 'full' },
          { path: 'vendor', redirectTo: 'vendor', pathMatch: 'full' },
          { path: 'vendor', component: VendorComponent, canActivate: [AuthGuardService] },
          { path: 'managevolume', component: ManageVolumeComponent, canActivate: [AuthGuardService] },
          { path: 'userrole', component: UserRoleManageComponent, canActivate: [AuthGuardService] },
          { path: 'account', component: AccChkDocManageComponent, data: { selectTab: 'account' }, canActivate: [AuthGuardService] },
          { path: 'check', component: AccChkDocManageComponent, data: { selectTab: 'check' }, canActivate: [AuthGuardService] },
          { path: 'document', component: AccChkDocManageComponent, data: { selectTab: 'document' }, canActivate: [AuthGuardService] },
          { path: 'uploadBUHR', component: UploadBUHRComponent, data: { selectTab: 'account' }, canActivate: [AuthGuardService]},
          { path: 'crnnumber', component: CrNumberComponent, canActivate: [AuthGuardService] },

          // { path: 'acmapping', component: AccChkDocManageComponent, data: { selectTab: 'account & Check Mapping' }, canActivate: [AuthGuardService] },
          // { path: 'cdmapping', component: AccChkDocManageComponent, data: { selectTab: 'check & Document Type Mapping' }, canActivate: [AuthGuardService] },
        ]
      },
      //{ path: 'admin/vendor', component: ManageVolumeComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/vendor', component: VendorComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/addvendor', component: AddVendorComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/editvendor/:vendorid', component: AddVendorComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/managevolume', component: ManageVolumeComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/member/:vendorid', component: ViewMemberComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/addmember/:vendorid', component: AddMemberComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/editmember/:vendorid/:memberid', component: AddMemberComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/role', component: ViewRoleComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/addrole', component: AddRoleComponent, canActivate: [AuthGuardService] },
      // { path: 'admin/editrole/:roleid', component: AddRoleComponent, canActivate: [AuthGuardService] },
      { path: 'admin/stdcheck', component: CheckConfigComponent, canActivate: [AuthGuardService] },
      { path: 'initiatecase', component: InitiateCaseComponent, canActivate: [AuthGuardService] },
      { path: 'admin/holiday', component: HolidayComponent, canActivate: [AuthGuardService] },
    ]
  },
  { path: 'callback', component: CallbackComponent },

];

const externalRoutes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  // { path: 'login/candidate', component: LoginComponent, data: { selectTab: 'candidate' } },
  // { path: 'login/vendor', component: LoginComponent, data: { selectTab: 'vendor' } },

  { path: 'signup', component: RegistryComponent },
  { path: 'forgotpswd', component: ForgotPasswordComponent },

  // // { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'app', component: AppMainComponent,
    children: [
      { path: 'home', redirectTo: 'home/search', pathMatch: 'full' },
      { path: 'home/dashboard', component: HomeComponent, data: { selectTab: 'dashboard' }, canActivate: [AuthGuardService] },
      { path: 'home/search', component: HomeComponent, data: { selectTab: 'search' }, canActivate: [AuthGuardService] },
      { path: 'home/search/case/:caseid', component: RequestComponent, canActivate: [AuthGuardService] },
      { path: 'home/closedgreen', component: HomeComponent, data: { selectTab: 'closedgreen' }, canActivate: [AuthGuardService] },
      { path: 'reports', redirectTo: 'reports/masterreport', pathMatch: 'full' },
      { path: 'reports/masterreport', component: ReportComponent, data: { selectTab: 'masterreport' }, canActivate: [AuthGuardService] },
      { path: 'reports/slareport', component: ReportComponent, data: { selectTab: 'slareport' }, canActivate: [AuthGuardService] },
      { path: 'reports/vendorslareport', component: ReportComponent, data: { selectTab: 'vendorslareport' }, canActivate: [AuthGuardService] },
      { path: 'reports/vendorreport', component: ReportComponent, data: { selectTab: 'vendorreport' }, canActivate: [AuthGuardService] },
      { path: 'reports/userwiseaudittrailreport', component: ReportComponent, data: { selectTab: 'userwiseaudittrailreport' }, canActivate: [AuthGuardService] },
      {
        path: 'admin', component: AdminComponent, canActivate: [AuthGuardService],
        children: [
          { path: '', redirectTo: 'vendor', pathMatch: 'full' },
          { path: 'vendor', redirectTo: 'vendor', pathMatch: 'full' },
          { path: 'vendor', component: VendorComponent, canActivate: [AuthGuardService] },
          { path: 'managevolume', component: ManageVolumeComponent, canActivate: [AuthGuardService] },
          { path: 'userrole', component: UserRoleManageComponent, canActivate: [AuthGuardService] },
          { path: 'account', component: AccChkDocManageComponent, data: { selectTab: 'account' }, canActivate: [AuthGuardService] },
          { path: 'check', component: AccChkDocManageComponent, data: { selectTab: 'check' }, canActivate: [AuthGuardService] },
          { path: 'document', component: AccChkDocManageComponent, data: { selectTab: 'document' }, canActivate: [AuthGuardService] },
          { path: 'crnnumber', component: CrNumberComponent, canActivate: [AuthGuardService] },

          // { path: 'acmapping', component: AccChkDocManageComponent, data: { selectTab: 'account & Check Mapping' }, canActivate: [AuthGuardService] },
          // { path: 'cdmapping', component: AccChkDocManageComponent, data: { selectTab: 'check & Document Type Mapping' }, canActivate: [AuthGuardService] },
        
        ]
      },
    ]
  },

];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(
    (environment.AppInstance == 'INTERNAL' ? internalRoutes : externalRoutes),
    { useHash: true }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
