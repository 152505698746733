export class ProcessResponse<T> {
    Data!: T;
    Status!: number;
    Message!: string;
    EventId!: string;
}

export class ProcessResponse1<T> {
    data!: T;
    status!: number;
    message!: string;
    eventId!: string;
}

export interface UserInfo {
    name: string;
    email: string;
    username: string;
    userid: number;
    actingusername: string;
    actinguserid: number;

    exp?: number;
    iss?: string;
    aud?: string;
    jti?: string;
}

export interface ComponentConfig {
    // AppId: string;
    Component?: string;
    SubComponent?: string;
    ReferenceValueType?: string;
    ReferenceValue?: string;
    FieldTemplate?: any[];
    Permission?: string[];
    Properties?: any[];
    ComponentCode: string;
    ComponentViewId?: number;
}

export enum ProcessStatus {
    Success = 200,
    Queued = 202,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    NoContent = 404,
    Error = 500
}

export enum ReferenceValueType {
    Quality = "1",
    ProjectId = "2",
    Admin = "3"
}
export enum RowLockStatus {
    None = 0,
    Locked = 1,
    Edit = 2
}

export interface HubGroupMember {

    GroupName: string;
    UserId: number;
    Name: string;
    ConnectionId: string;
    LastActionOn: Date;
    RowIds: string[];
}

export class GroupDataRowLocks {
    constructor(init?: Partial<GroupDataRowLocks>) {
        Object.assign(this, init);
    }

    GroupName!: string;
    Locks!: DataRowlock[];
    DataObject: any;
    DataUniqueKey!: string;
}

export class DataRowlock {
    constructor(init?: Partial<DataRowlock>) {
        Object.assign(this, init);
    }
    GroupName!: string;
    UserId!: number;
    RowId!: string;
    Name!: string;
    ConnectionId!: string;
    LockedOn!: Date;
    RowLockStatus!: RowLockStatus;
    Data: any;
}


export enum ComponentCodes {
    HomeComponent = 'C0001',
    AppComponent = 'C0002',
    RequestComponent = 'C0003',
    CheckComponent = 'C0004',
    SearchComponent = 'C0005',
    UpdateOverAllComponent = 'C0006',
    QualityControl = 'C0007',
    NonGreen = 'C0008',
    ReportComponent = 'C0009',
    CentralHR = 'C0010',
    BUHR = 'C0011',
    AdminComponent = 'C0012',
    InitiateCase = 'C0013',
    BGVHead = 'C0014'
}

export enum RoleCodes {
    Candidate = 1,
    Employee = 2,
    Recruiter = 3,
    VendorAdmin = 4,
    VendorMember = 5,
    BGVAdmin = 6,
    QC = 7,
    NonGreen = 8,
    IntiateInsuff = 9,
    NoRole = 10,
    CentralHR = 11,
    BUHR = 12,
    OnBoardTeam = 13,
    ProjInitiator = 14,
    BGVHead = 17
}

export enum ActionCode {
    Update_Check = 'AC001',
    Partial_Save = 'AC002',
    Submit_Interim_Report = 'AC003',
    QC_Accept = 'AC004',
    QC_Reject = 'AC005',
    Submit_Final_Report = 'AC006',
    NG_Submit_to_Central_HR = 'AC007',
    NG_Submit_to_Vendor = 'AC008',
    NG_Raise_L3_Insufficiency = 'AC009',
    NG_Submit_for_termination = 'AC010',
    BUHR_Agree_for_Termination = 'AC011',
    Assign_to_nongreen = 'AC012',
    Submit_Supplimentary_Report = 'AC013',
    Close_case = 'AC014',
    Case_Creation = 'AC015',
    Vendor_Stop_Case = 'AC016',
    QC_Stop_Case = 'AC017',
    Recruiter_Stop_Case = 'AC018',
    Accept_From_Central_HR = 'AC019',
    Reject_From_Central_HR = 'AC020',
    Submit_Highest_Education_Previous_Employment = 'AC021',
    Clear_Insufficiency = 'AC022',
    InitiateToEmployee = 'AC023',
    InitiateToVendor = 'AC024',
    Initiate_Project_BGV = 'AC025',
    Submit_Document = 'AC026',
    Add_Document_Type = 'AC027',
    Delete_Document_Type = 'AC028',
    Insufficiency_On_Hold = 'AC029',
    Good_to_go_flag_update = 'AC030',
    BGV_Manager_Save = 'AC031',
    FOLLOWUPMAIL='REM041',
    SHOWCAUSENOTICE='REM037',
    NG_Submit_to_BGV_Head='AC033',
    NG_Submit_to_BGV_Head_Approval_For_Termination='AC036'


}

export enum StatusColorCode {
    Green = 'SC001',
    Red = 'SC002',
    Amber = 'SC003',
    Green_desc = 'Green',
    Red_desc = 'Red',
    Amber_desc = 'Amber'
}

export enum BGVStatus {
    Stopped_Declined_offer_or_Resigned_or_Terminated = '10036',
    Non_Green_Terminated = '10028',
    // Non_Green_On_hold_by_BUHR_1 = '10070',
    // Non_Green_On_hold_by_BUHR_2 = '10071',
    // Non_Green_On_hold_by_BUHR_3 = '10072',
     Non_Green_Insufficiency_cleared_by_BUHR_1 = '10073',
     Non_Green_Insufficiency_cleared_by_BUHR_2 = '10074',
    Non_Green_Insufficiency_cleared_by_BUHR_3 = '10069',
    NonGreen_Process_initiated_for_termination_1 = '10019',
    // NonGreen_Process_initiated_for_termination_2 = '10075',
    // NonGreen_Process_initiated_for_termination_3 = '10076',
    Project_BGV_Case_Created = '10064',
    Document_Upload_Pending_with_Employee = '10065',
    Document_Upload_Pending_with_BGV_Manager='10077',
    Request_received_for_initiation = '10001',
    Insufficiency_Not_Cleared = '10082',
    L1_Insufficiency_Mandate_documents = '10003',
    L2_Insufficiency_Additional_details_or_documents = '10006',
    Prev_Employment__InProgress = '10066',
    Highest_Education__InProgress = '10067',
    Final_Semester__InProgress = '10068',
    Insufficiency_Paidup_Capital_Need_Financial_documents_1 = '10039',
    Insufficiency_Paidup_Capital_Need_Financial_documents_2 = '10057',
    Insufficiency_Paidup_Capital_Need_Financial_documents_3 = '10056',
    Insufficiency_Possible_Suspect_Need_Financial_documents_1 = '10002',
    Insufficiency_Possible_Suspect_Need_Financial_documents_2 = '10054',
    Insufficiency_Possible_Suspect_Need_Financial_documents_3 = '10055',
    L1_Insufficiency_cleared_1 = '10005',
    L1_Insufficiency_cleared_2 = '10060',
    L1_Insufficiency_cleared_3 = '10062',
    L2_Insuff_cleared_to_vendor_1 = '10007',
    L2_Insuff_cleared_to_vendor_2 = '10061',
    L2_Insuff_cleared_to_vendor_3 = '10063',
    In_progress = '10004',
    L3_Insufficiency_Additional_details_or_documents = '10027',
    L3_Insufficiency_Additional_details_or_documents_1 = '10083',
    L3_Insufficiency_Additional_details_or_documents_2 = '10084',
    L3_Insufficiency_cleared = '10017',
    Verified_non_Green_1 = '10040',
    Verified_non_Green_2 = '10035',
    Verified_non_Green_3 = '10058',
    Verified_non_Green_4 = '10059',
    Interim_Green_IR_rejected = '10010',
    Interim_NonGreen_IR_Rejected = '10011',
    Interim_NonGreen_IR_Reinitiated = '10042',
    Interim_cleared_IR__Highest_education_pending = '10029',
    Interim_cleared_IR__Highest_education_check_in_progress = '10030',
    Interim_cleared_IR__Final_Semester_Documents_pending = '10031',
    Interim_cleared_IR_P_Emp__Insufficiency = '10032',
    Interim_cleared_IR_P_Emp__Inprogress = '10033',
    Interim_NonGreen_IR_submitted_1 = '10009',
    Interim_NonGreen_IR_submitted_2 = '10052',
    Interim_NonGreen_IR_Accepted = '10013',
    Interim_Green_IR_submitted = '10008',
    Interim_Green_IR_Accepted = '10012',
    NonGreen_SR_Rejected = '10023',
    Final_Green_FR_rejected = '10025',
    Final_NonGreen_FR_rejected = '10026',
    NonGreen_SR_Reinitiated = '10043',
    Non_Green__Exception_Approval_Rejected_1 = '10044',
    Non_Green__Exception_Approval_Rejected_2 = '10047',
    Non_Green__Exception_Approval_Rejected_3 = '10050',
    Non_Green__Awaiting_for_Exception_Approval_1 = '10018',
    Non_Green__Awaiting_for_Exception_Approval_2 = '10046',
    Non_Green__Awaiting_for_Exception_Approval_3 = '10049',
    Final_NonGreen_FR_submitted = '10015',
    Final_NonGreen_FR_Under_NonGreen_Team_Review = '10016',
    FinalFR__NonGreen__Reinitiated = '10022',
    Final_Green_FR_submitted = '10014',
    Final_Green_FR_Accepted = '10053',
    NonGreen_SR_Submitted = '10021',
    Green_SR_Submitted = '10038',
    Converted_Green_SR_Submitted = '10041',
    Non_Green__Exception_Approval_Accepted_1 = '10045',
    Non_Green__Exception_Approval_Accepted_2 = '10048',
    Non_Green__Exception_Approval_Accepted_3 = '10051',
    Converted_Green = '10037',
    Verified_Green = '10034',
    Verification_Completed_Pending_with_BGV_Manager ='10078',
    Closed__Green = '10024',
    Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_1 = '10090',
    Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_2 = '10091',
    Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_3 = '10092',
    NonGreen_Process_initiated_for_termination_From_BGV_Head_1 = '10093',
    Non_Green__termination_Approval_Accepted_By_BGV_Head = '10094',
    Non_Green__termination_Approval_Rejected_By_BGV_Head = '10095',
    CheckStoped='10098',

}

export enum InitiateStatus {
    Verification_Completed = 'I001',
    Yet_To_Initiate = 'I002',
    Not_Applicable = 'I003',
    Verification_Completed_Desc = 'Verification Completed',
    Yet_To_Initiate_Desc = 'Yet To Initiate',
    Not_Applicable_Desc = 'Not Applicable'
}

export enum SCREEN_SIZE {
    XS,     // Extra small devices (portrait phones, less than 576px)
    SM,     // Small devices (landscape phones, 576px and up)
    MD,     // Medium devices (tablets, 768px and up)
    LG,     // Large devices (desktops, 992px and up)
    XL      // Extra large devices (large desktops, 1200px and up)
}

export interface DowntimeMessage {
    DowntimeMessage: string;
}